import React from 'react'

interface ServerStatusTagProps {
  status: number
}
/**
 * This is used by the server to detect the status code to return in express
 */
const ServerStatusTag = ({ status }: ServerStatusTagProps): JSX.Element => (
  <div id='server-status-code' data-status={status} />
)

export default ServerStatusTag
