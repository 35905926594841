import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { AboutContainer } from '../containers/about'
import { AdvertiseContainer } from '../containers/advertise'
import { ArticleDetailsContainer } from '../containers/article-details'
import { BecomeAWriterContainer } from '../containers/become-a-writer'
import { CategoriesContainer } from '../containers/categories'
import { CategoryDetailsContainer } from '../containers/category-details'
import { ContactContainer } from '../containers/contact'
import { HomeContainer } from '../containers/home'
import { PageNotFoundContainer } from '../containers/page-not-found'
import { WriterDetailsContainer } from '../containers/writer-details'
import { WritersContainer } from '../containers/writers'
import { FooterLayout } from '../layouts/footer'
import { HeaderLayout } from '../layouts/header'
import ErrorBoundaryWrapper from '../error-boundary-wrapper'

// export const routesV2 = [
//   {
//     element: <HeaderLayout />,
//     children: [
//       {
//         element: <FooterLayout />,
//         errorElement: <ErrorBoundry />,
//         children: [
//           { path: '/', element: <HomeContainer /> },
//           { path: '/categories', element: <CategoriesContainer /> },
//           { path: '/category/:urlKey', element: <CategoryDetailsContainer /> },
//           { path: '/writers', element: <WritersContainer /> },
//           { path: '/writer/:urlKey', element: <WriterDetailsContainer /> },
//           { path: '/article/:urlKey', element: <ArticleDetailsContainer /> },
//           { path: '/about', element: <AboutContainer /> },
//           { path: '/contact', element: <ContactContainer /> },
//           { path: '/advertise', element: <AdvertiseContainer /> },
//           { path: '/become-a-writer', element: <BecomeAWriterContainer /> },
//           { path: '*', element: <PageNotFoundContainer /> },
//         ],
//       },
//     ],
//   },
// ]

const MainRouter = (): JSX.Element => (
  <ErrorBoundaryWrapper>
    <Routes>
      <Route element={<HeaderLayout />}>
        <Route element={<FooterLayout />}>
          <Route path='/' element={<HomeContainer />} />
          <Route path='/categories' element={<CategoriesContainer />} />
          <Route path='/category/:urlKey' element={<CategoryDetailsContainer />} />
          <Route path='/writers' element={<WritersContainer />} />
          <Route path='/writer/:urlKey' element={<WriterDetailsContainer />} />
          <Route path='/article/:urlKey' element={<ArticleDetailsContainer />} />
          <Route path='/about' element={<AboutContainer />} />
          <Route path='/contact' element={<ContactContainer />} />
          <Route path='/advertise' element={<AdvertiseContainer />} />
          <Route path='/become-a-writer' element={<BecomeAWriterContainer />} />
          <Route path='*' element={<PageNotFoundContainer />} />
        </Route>
      </Route>
    </Routes>
  </ErrorBoundaryWrapper>
)

export default MainRouter
