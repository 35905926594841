import React from 'react'
import { Link } from 'react-router-dom'
import { Category } from '../../types/category.types'

export type ListingCategoryType = Pick<Category, 'title' | 'urlKey' | 'image'>

interface ListingCategoryProps {
  category: ListingCategoryType
  customClassName?: string
  elementRef?: ((instance: HTMLElement | null) => void) | React.RefObject<HTMLElement> | null | undefined
}
const ListingCategory = ({ category, customClassName = '', elementRef }: ListingCategoryProps): JSX.Element => (
  <article className={`listing-category ${customClassName}`} ref={elementRef}>
    <h1 className='listing-category__title'>{category.title}</h1>
    <Link title={category.title} to={`/category/${category.urlKey}`}>
      <img
        sizes='(min-width: 696px) calc(50vw - 20px), calc(100vw - 20px)'
        src={`https://assets.qesheq.com/resized/676x447/${category.image}`}
        srcSet={`https://assets.qesheq.com/resized/355x235/${category.image} 355w, https://assets.qesheq.com/resized/676x447/${category.image} 676w`}
        alt={category.title}
        className='listing-category__image'
      />
    </Link>
  </article>
)

export default ListingCategory
