/* eslint-disable no-underscore-dangle */
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import React, { ReactNode } from 'react'
import { GRAPHQL_URL } from '../../consts'

declare const window: any

interface PropsInterface {
  children: ReactNode
  client?: ApolloClient<NormalizedCacheObject>
}

let clientCache = new InMemoryCache()
if (typeof window !== 'undefined' && window.__APOLLO_STATE__) {
  clientCache = clientCache.restore(window.__APOLLO_STATE__)
}

const defaultClient = new ApolloClient({
  uri: GRAPHQL_URL,
  cache: clientCache,
  ssrForceFetchDelay: 100,
})

const GraphQLProvider = ({ children, client }: PropsInterface): JSX.Element => (
  <ApolloProvider client={client || defaultClient}>{children}</ApolloProvider>
)
export default GraphQLProvider
