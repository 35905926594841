import React, { useEffect } from 'react'
import { Button, InputField, TextAreaField } from '@qesheq/qesheq-ui'

import { useAdvertiseMutation, useInputState } from '../../../hooks'
import './advertise-form.styles.css'

const AdvertiseForm = (): JSX.Element => {
  const {
    value: fullname,
    setValue: setFullname,
    error: fullnameError,
    validate: validateFullname,
    onBlur: fullnameOnBlur,
    reset: resetFullname,
  } = useInputState({ initialValue: '', validation: { required: true } })

  const {
    value: company,
    setValue: setCompany,
    error: companyError,
    validate: validateCompany,
    onBlur: companyOnBlur,
    reset: resetCompany,
  } = useInputState({ initialValue: '', validation: { required: true } })

  const {
    value: email,
    setValue: setEmail,
    error: emailError,
    validate: validateEmail,
    onBlur: emailOnBlur,
    reset: resetEmail,
  } = useInputState({ initialValue: '', validation: { required: true, email: true } })

  const {
    value: phone,
    setValue: setPhone,
    error: phoneError,
    validate: validatePhone,
    onBlur: phoneOnBlur,
    reset: resetPhone,
  } = useInputState<string | undefined>({ initialValue: '', validation: {} })

  const {
    value: budget,
    setValue: setBudget,
    error: budgetError,
    validate: validateBudget,
    onBlur: budgetOnBlur,
    reset: resetBudget,
  } = useInputState({ initialValue: '', validation: { required: true } })

  const {
    value: duration,
    setValue: setDuration,
    error: durationError,
    validate: validateDuration,
    onBlur: durationOnBlur,
    reset: resetDuration,
  } = useInputState({ initialValue: '', validation: { required: true } })

  const {
    value: message,
    setValue: setMessage,
    error: messageError,
    validate: validateMessage,
    onBlur: messageOnBlur,
    reset: resetMessage,
  } = useInputState<string>({ initialValue: '', validation: { required: true } })

  const { sendAdvertise, isCreated, isLoading, error, reset: resetMutation } = useAdvertiseMutation()

  const handleSubmit = async (event: React.SyntheticEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()
    let isFormValid = true
    isFormValid = validateFullname() && isFormValid
    isFormValid = validateEmail() && isFormValid
    isFormValid = validatePhone() && isFormValid
    isFormValid = validateMessage() && isFormValid
    isFormValid = validateBudget() && isFormValid
    isFormValid = validateDuration() && isFormValid
    isFormValid = validateCompany() && isFormValid

    if (!isLoading && isFormValid) {
      const payload = {
        name: fullname,
        email,
        phone,
        message,
        budget,
        duration,
        company,
      }

      sendAdvertise(payload)
    }
  }

  useEffect(() => {
    if (error) {
      console.log('Error while submittion advertise form', error)
      setTimeout(() => {
        resetMutation()
      }, 3000)
    }
  }, [error])

  useEffect(() => {
    if (isCreated) {
      setTimeout(() => {
        resetBudget()
        resetCompany()
        resetDuration()
        resetEmail()
        resetMessage()
        resetFullname()
        resetPhone()
        resetMutation()
      }, 5000)
    }
  }, [isCreated])

  return (
    <div className='advertise-form page-padding'>
      <div className='advertise-form__container centered-container'>
        <form action='' className='advertise-form__form' onSubmit={handleSubmit}>
          <InputField
            value={fullname}
            onChange={setFullname}
            errorMessage={fullnameError}
            label='الأسم'
            onBlur={fullnameOnBlur}
            customClassName='advertise-form__input-field'
            isDisabled={isLoading}
          />
          <InputField
            value={company}
            onChange={setCompany}
            errorMessage={companyError}
            label='إسم الشركة'
            onBlur={companyOnBlur}
            customClassName='advertise-form__input-field'
            isDisabled={isLoading}
          />
          <InputField
            value={phone}
            onChange={setPhone}
            errorMessage={phoneError}
            label='رقم الهاتف'
            onBlur={phoneOnBlur}
            customClassName='advertise-form__input-field'
            isDisabled={isLoading}
          />
          <InputField
            value={email}
            onChange={setEmail}
            errorMessage={emailError}
            label='البريد الالكتروني'
            onBlur={emailOnBlur}
            customClassName='advertise-form__input-field'
            isDisabled={isLoading}
          />
          <InputField
            value={budget}
            onChange={setBudget}
            errorMessage={budgetError}
            label='الميزانية'
            onBlur={budgetOnBlur}
            customClassName='advertise-form__input-field'
            isDisabled={isLoading}
          />
          <InputField
            value={duration}
            onChange={setDuration}
            errorMessage={durationError}
            label='الفترة الزمنية'
            onBlur={durationOnBlur}
            customClassName='advertise-form__input-field'
            isDisabled={isLoading}
          />
          <TextAreaField
            value={message}
            onChange={setMessage}
            errorMessage={messageError}
            label='الرسالة'
            onBlur={messageOnBlur}
            customClassName='advertise-form__text-area-field'
            isDisabled={isLoading}
          />
          <Button
            label='أرسل'
            type='input'
            layout='solid'
            customClassName='advertise-form__submit-button'
            isDisabled={isLoading}
          />
          <div className={`advertise-form__error-container ${error ? 'advertise-form__error-container--visible' : ''}`}>
            <span className='advertise-form__error-title'>عذراً</span>
            <span className='advertise-form__error-message'> حدث خطأ ما</span>
          </div>
          <div
            className={`advertise-form__success-container ${
              isCreated ? 'advertise-form__success-container--visible' : ''
            }`}>
            <span className='advertise-form__success-title'>شكرا لك</span>
            <span className='advertise-form__success-message'>لقد تم إستلام رسالتك</span>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AdvertiseForm
