import React, { useCallback, useRef } from 'react'

import './articles-listing.styles.css'
import ListingArticle from './listing-article.component'
import { Article } from '../../types/article.types'

const skeletonLoopArray = [...Array(3)]

interface ArticlesListingProps {
  title?: string
  articles?: Pick<Article, 'id' | 'title' | 'urlKey' | 'category' | 'image' | 'writer' | 'smallDescription' | 'date'>[]
  isLoading: boolean
  customClassName?: string
  customArticleClassName?: string
  listKey: string
  loadMoreArticles: () => void
  hasMoreArticles: boolean
}

const ArticlesListing = (props: ArticlesListingProps): JSX.Element => {
  const {
    title,
    articles,
    customClassName,
    customArticleClassName,
    listKey,
    isLoading,
    loadMoreArticles,
    hasMoreArticles,
  } = props

  const observer = useRef<IntersectionObserver>()
  const lastArticleReference = useCallback(
    (element: HTMLElement | null) => {
      if (!loadMoreArticles || isLoading) {
        return
      }
      if (observer.current) {
        observer.current.disconnect()
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreArticles && !isLoading) {
          loadMoreArticles()
        }
      })
      if (element) observer?.current?.observe(element)
    },
    [isLoading, loadMoreArticles, hasMoreArticles]
  )

  return (
    <section className={`articles-listing page-padding ${customClassName || ''}`}>
      {title && (
        <div className='centered-container'>
          <h1 className='articles-listing__title'>{title}</h1>
        </div>
      )}
      <div className='centered-container articles-listing__container'>
        {articles?.length &&
          articles.map((article, index) => (
            <ListingArticle
              article={article}
              customArticleClassName={customArticleClassName}
              key={`listing-article-${listKey}-${article.id}`}
              elementRef={index + 1 === articles.length ? lastArticleReference : undefined}
            />
          ))}
        {isLoading &&
          skeletonLoopArray.map((_, index) => (
            <ListingArticle
              // eslint-disable-next-line react/no-array-index-key
              key={`listing-article-skeleton-${index}`}
            />
          ))}
      </div>
    </section>
  )
}

export default ArticlesListing
