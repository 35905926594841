import React from 'react'
import { ArticlesListing, FeatureBlocks, HelmetMetas, Hero } from '../../components'
import { useHomeQuery } from '../../hooks'

import './home.styles.css'

const HomeContainer = (): JSX.Element => {
  const {
    hero,
    features,
    articles,
    isLoading,
    metaTitle,
    metaDescription,
    metaImage,
    hasMore,
    loadMore,
    isLoadingMore,
  } = useHomeQuery({
    pageSize: 9,
  })

  return (
    <>
      <HelmetMetas title={metaTitle} description={metaDescription} image={metaImage} url='' />

      <Hero image={hero?.image} articles={hero?.articles} isLoading={isLoading} />
      <FeatureBlocks articles={features} isLoading={isLoading} customClassName='home-page__featured-articles' />
      <ArticlesListing
        articles={articles}
        isLoading={isLoading || isLoadingMore}
        listKey='home'
        customClassName='home-page__listing'
        loadMoreArticles={loadMore}
        hasMoreArticles={hasMore}
      />
    </>
  )
}

export default HomeContainer
