import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { ListingArticleInterface } from '../../components/articles-listing/listing-article.component'
import { Article } from '../../types/article.types'
import { Category } from '../../types/category.types'

const articleFields = `
  total
  limit
  skip
  data {
    id
    urlKey
    title
    image
    date
    smallDescription
    category {
      urlKey
      title
    }
    writer {
      urlKey
      fullname
    }
  }
`

const initialQuery = gql`
  query article($urlKey: String!, $categoryArticlesSort: SortInput, $categoryArticlesPagination: PaginationInput, $categoryArticlesFilters: ArticleFiltersInput, $categoriesSort: SortInput, $categoriesPagination: PaginationInput, $categoriesFilters: CategoryFiltersInput, $articlesSort: SortInput, $articlesPagination: PaginationInput, $articlesFilters: ArticleFiltersInput) {
    article: articleByUrlKey(urlKey: $urlKey) {
      title
      description
      date
      image
      views
      smallDescription
      tags {
        id
        title
      }
      writer {
        fullname
        urlKey
      }
      category {
        urlKey
        title
        articles(sort: $categoryArticlesSort, pagination: $categoryArticlesPagination, filters: $categoryArticlesFilters) {
          data {
            id
            urlKey
            title
            date
            image
            writer {
              fullname
              urlKey
            }
          }
        }
      }
    }
    categories(sort: $categoriesSort, pagination: $categoriesPagination, filters: $categoriesFilters){
      data{
        id
        urlKey
        title
        image
      }
    }
    articles(sort: $articlesSort, pagination: $articlesPagination, filters: $articlesFilters){
      ${articleFields}
    }
  }
`
const articlesQuery = gql`
  query articles(
    $sort: SortInput
    $pagination: PaginationInput
    $filters: ArticleFiltersInput
  ) {
    articles(sort: $sort, pagination: $pagination, filters: $filters) {
      ${articleFields}
    }
  }
`

interface ArticleQueryProps {
  urlKey: string
  pageSize?: number
  pageNumber?: number
}

interface ArticleQueryInterface {
  title?: Article['title']
  description?: Article['description']
  smallDescription?: Article['smallDescription']
  date?: Article['date']
  image?: Article['image']
  views?: Article['views']
  tags?: Article['tags']
  category?: Pick<Article['category'], 'urlKey' | 'title'>
  relatedArticles?: Pick<Article, 'id' | 'urlKey' | 'title' | 'date' | 'image' | 'writer'>[]
  writer?: Pick<Article['writer'], 'urlKey' | 'fullname'>
  categories?: Pick<Category, 'id' | 'urlKey' | 'image' | 'title'>[]
  articles?: ListingArticleInterface[]
  isLoading: boolean
  isLoadingMore: boolean
  hasMore: boolean
  loadMore: () => void
}

const listingSort = {
  by: 'date',
  direction: 'desc',
}

const useArticleQuery = ({ urlKey, pageSize = 9, pageNumber = 1 }: ArticleQueryProps): ArticleQueryInterface => {
  const { loading: isLoading, data: articleData } = useQuery(initialQuery, {
    variables: {
      urlKey,
      categoryArticlesSort: {
        by: 'date',
        direction: 'desc',
      },
      categoryArticlesFilters: {
        isPublished: true,
        excludeUrlKeys: [urlKey],
      },
      categoryArticlesPagination: {
        limit: 4,
      },
      categoriesSort: {
        by: 'articlesCount',
        direction: 'desc',
      },
      categoriesFilters: {
        isPublished: true,
      },
      categoriesPagination: {
        limit: 4,
      },
      articlesSort: listingSort,
      articlesFilters: { isPublished: true, excludeUrlKeys: [urlKey] },
      articlesPagination: {
        limit: pageSize * pageNumber,
      },
    },
    notifyOnNetworkStatusChange: true,
    context: {
      headers: {
        'x-increment-view': 'article', // required to increment article view count
      },
    },
  })

  const [fetchArticles, { loading: isLoadingMore, data: articlesData, fetchMore: fetchMoreArticles }] =
    useLazyQuery(articlesQuery)

  const articlesListingData = articlesData?.articles
  const listingArticles = useMemo(
    () => [...(articleData?.articles?.data || []), ...(articlesListingData?.data || [])],
    [articleData?.articles, articlesListingData?.data]
  )
  const total = articlesListingData?.total || articleData?.articles?.total || 0
  const hasMore = useMemo(() => total > listingArticles.length, [listingArticles, total])

  const loadMore = (): void => {
    if (hasMore && !isLoadingMore) {
      const queryOptions = {
        variables: {
          sort: listingSort,
          filters: { isPublished: true, excludeUrlKeys: [urlKey] },
          pagination: {
            limit: pageSize,
            skip: listingArticles?.length || 0,
          },
        },
        notifyOnNetworkStatusChange: true,
      }
      if (!articlesListingData?.data) {
        fetchArticles({ ...queryOptions })
      } else {
        fetchMoreArticles({
          ...queryOptions,
          updateQuery: (previousData, { fetchMoreResult }) => {
            if (!fetchMoreResult) return previousData
            return {
              articles: {
                ...previousData.articles,
                data: [...(previousData?.articles?.data || []), ...(fetchMoreResult.articles?.data || [])],
              },
            }
          },
        })
      }
    }
  }

  return {
    title: articleData?.article?.title,
    description: articleData?.article?.description,
    smallDescription: articleData?.article?.smallDescription,
    date: articleData?.article?.date,
    image: articleData?.article?.image,
    views: articleData?.article?.views,
    tags: articleData?.article?.tags,
    category: articleData?.article?.category && {
      urlKey: articleData?.article?.category?.urlKey,
      title: articleData?.article?.category?.title,
    },
    writer: articleData?.article?.writer && {
      urlKey: articleData?.article?.writer?.urlKey,
      fullname: articleData?.article?.writer?.fullname,
    },
    relatedArticles: articleData?.article?.category?.articles?.data,
    isLoading,
    categories: articleData?.categories?.data,
    articles: listingArticles,
    isLoadingMore,
    hasMore,
    loadMore,
  }
}

export default useArticleQuery
