import React from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { useNavigate } from 'react-router-dom'
import { Error } from './components'

const ErrorFallbackContainer = ({ error, resetErrorBoundary }: FallbackProps): JSX.Element => {
  const navigate = useNavigate()

  const handleErrorReset = (): void => {
    navigate('/')
    resetErrorBoundary()
  }
  return <Error error={{ status: 500 }} onReset={handleErrorReset} />
}

const ErrorBoundaryWrapper = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <ErrorBoundary FallbackComponent={ErrorFallbackContainer}>{children}</ErrorBoundary>
)

export default ErrorBoundaryWrapper
