import { gql, useQuery } from '@apollo/client'

const query = gql`
  query advertise {
    advertise {
      image
      metaTitle
      metaDescription
      metaImage
    }
  }
`

interface AdvertiseQueryInterface {
  image?: string
  metaTitle?: string
  metaDescription?: string
  metaImage?: string
  isLoading: boolean
}

const useAdvertiseQuery = (): AdvertiseQueryInterface => {
  const { loading: isLoading, data: advertiseData } = useQuery(query)

  return {
    image: advertiseData?.advertise?.image,
    metaTitle: advertiseData?.advertise?.metaTitle,
    metaDescription: advertiseData?.advertise?.metaDescription,
    metaImage: advertiseData?.advertise?.metaImage,
    isLoading,
  }
}

export default useAdvertiseQuery
