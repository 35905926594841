import React from 'react'
import { Link } from 'react-router-dom'

import './red-label.styles.css'

interface RedLabelProps {
  label: string
  customClassName?: string
  to?: string
}

const RedLabel = ({ label, to, customClassName }: RedLabelProps): JSX.Element => {
  if (to) {
    return (
      <Link className={`red-label ${customClassName || ''}`} to={to} title={label}>
        {label}
      </Link>
    )
  }

  return <div className={`red-label ${customClassName || ''}`}>{label}</div>
}

export default RedLabel
