import React from 'react'
import { BecomeAWriterForm, HelmetMetas, MiniHero } from '../../components'
import { useBecomeAWriterQuery } from '../../hooks'

import './become-a-writer.styles.css'

const BecomeAWriterContainer = (): JSX.Element => {
  const { image, metaTitle, metaDescription, metaImage } = useBecomeAWriterQuery()

  return (
    <>
      <HelmetMetas title={metaTitle} description={metaDescription} image={metaImage} url='become-a-writer' />

      <MiniHero image={image} title='انضم إلى كشك' crumbs={[{ label: 'الرئيسية', link: '/' }]} />

      <BecomeAWriterForm />
    </>
  )
}

export default BecomeAWriterContainer
