import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { ListingArticleInterface } from '../../components/articles-listing/listing-article.component'
import { FeaturedBlockInterface } from '../../components/featured-blocks/featured-blocks.component'
import { HeroProps } from '../../components/main-hero/hero.component'
import { Page } from '../../types/page.types'

const articleFields = `
  total
  limit
  skip
  data {
    id
    urlKey
    title
    image
    date
    smallDescription
    category {
      urlKey
      title
    }
    writer {
      urlKey
      fullname
    }
  }
`

const initialQuery = gql`
  query home(
    $featuresSort: SortInput
    $featuresPagination: PaginationInput
    $featuresFilters: ArticleFiltersInput
    $listingSort: SortInput
    $listingPagination: PaginationInput
    $listingFilters: ArticleFiltersInput
  ) {
    home {
      image
      metaTitle
      metaDescription
      metaImage
      articles {
        id
        urlKey
        title
        category {
          urlKey
          title
        }
      }
    }
    features: articles(sort: $featuresSort, pagination: $featuresPagination, filters: $featuresFilters) {
      data {
        id
        urlKey
        title
        image
        category {
          urlKey
          title
        }
      }
    }
    listing: articles(sort: $listingSort, pagination: $listingPagination, filters: $listingFilters) {
      ${articleFields}
    }
  }
`
const articlesQuery = gql`
  query articles(
    $sort: SortInput
    $pagination: PaginationInput
    $filters: ArticleFiltersInput
  ) {
    articles(sort: $sort, pagination: $pagination, filters: $filters) {
      ${articleFields}
    }
  }
`
interface HomeQueryProps {
  pageSize?: number
  pageNumber?: number
}
interface HomeQueryInterface {
  hero?: HeroProps
  features?: FeaturedBlockInterface[]
  articles?: ListingArticleInterface[]
  metaTitle?: Page['metaTitle']
  metaDescription?: Page['metaDescription']
  metaImage?: Page['metaImage']
  isLoading: boolean
  isLoadingMore: boolean
  hasMore: boolean
  loadMore: () => void
}

const listingSort = {
  by: 'date',
  direction: 'desc',
}

const listingFilters = {
  isPublished: true,
  isFeatured: false,
}

const useHomeQuery = ({ pageSize = 8, pageNumber = 1 }: HomeQueryProps): HomeQueryInterface => {
  const { loading: isLoading, data: homeData } = useQuery(initialQuery, {
    variables: {
      featuresSort: {
        by: 'date',
        direction: 'desc',
      },
      featuresFilters: {
        isFeatured: true,
        isPublished: true,
      },
      featuresPagination: {
        limit: 2,
      },
      listingSort,
      listingFilters,
      listingPagination: {
        limit: pageSize * pageNumber,
      },
    },
    notifyOnNetworkStatusChange: true,
  })

  const [fetchArticles, { loading: isLoadingMore, data: articlesData, fetchMore: fetchMoreArticles }] =
    useLazyQuery(articlesQuery)

  const articlesListingData = articlesData?.articles
  const listingArticles = useMemo(
    () => [...(homeData?.listing?.data || []), ...(articlesListingData?.data || [])],
    [homeData?.listing, articlesListingData?.data]
  )
  const total = articlesListingData?.total || homeData?.listing?.total || 0
  const hasMore = useMemo(() => total > listingArticles.length, [listingArticles, total])

  const loadMore = (): void => {
    if (hasMore && !isLoadingMore) {
      const queryOptions = {
        variables: {
          sort: listingSort,
          filters: listingFilters,
          pagination: {
            limit: pageSize,
            skip: listingArticles?.length || 0,
          },
        },
        notifyOnNetworkStatusChange: true,
      }
      if (!articlesListingData?.data) {
        fetchArticles({ ...queryOptions })
      } else {
        fetchMoreArticles({
          ...queryOptions,
          updateQuery: (previousData: any, { fetchMoreResult }: any) => {
            if (!fetchMoreResult) return previousData
            return {
              articles: {
                ...previousData.articles,
                data: [...(previousData?.articles?.data || []), ...(fetchMoreResult.articles?.data || [])],
              },
            }
          },
        })
      }
    }
  }

  return {
    hero: homeData?.home,
    features: homeData?.features?.data,
    articles: listingArticles || [],
    metaTitle: homeData?.home?.metaTitle,
    metaDescription: homeData?.home?.metaDescription,
    metaImage: homeData?.home?.metaImage,
    isLoading,
    isLoadingMore,
    hasMore,
    loadMore,
  }
}

export default useHomeQuery
