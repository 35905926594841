import { gql, useQuery } from '@apollo/client'
import React, { createContext, ReactNode } from 'react'
import { Category } from '../types/category.types'

interface LayoutDataContextInterface {
  categories?: Partial<Category>[]
  isLoading: boolean
}

interface LayoutDataProviderProps {
  children: ReactNode
}

const LayoutDataContext = createContext<LayoutDataContextInterface>({
  categories: undefined,
  isLoading: false,
})

const CategoriesQuery = gql`
  query Categories($sort: SortInput, $pagination: PaginationInput, $filters: CategoryFiltersInput) {
    categories(sort: $sort, filters: $filters, pagination: $pagination) {
      total
      limit
      skip
      data {
        id
        urlKey
        title
        inHeader
      }
    }
  }
`
const LayoutDataProvider = ({ children }: LayoutDataProviderProps): JSX.Element => {
  const { loading, data: categoriesData } = useQuery(CategoriesQuery, {
    notifyOnNetworkStatusChange: true,
  })

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <LayoutDataContext.Provider value={{ categories: categoriesData?.categories?.data || [], isLoading: loading }}>
      {children}
    </LayoutDataContext.Provider>
  )
}

export { LayoutDataContext, LayoutDataProvider }
