import { gql, useQuery } from '@apollo/client'

const query = gql`
  query becomeAWriter {
    becomeAWriter {
      image
      metaTitle
      metaDescription
      metaImage
    }
  }
`

interface BecomeAWriterQueryInterface {
  image?: string
  metaTitle?: string
  metaDescription?: string
  metaImage?: string
  isLoading: boolean
}

const useBecomeAWriterQuery = (): BecomeAWriterQueryInterface => {
  const { loading: isLoading, data: becomeAWriterData } = useQuery(query)

  return {
    image: becomeAWriterData?.becomeAWriter?.image,
    metaTitle: becomeAWriterData?.becomeAWriter?.metaTitle,
    metaDescription: becomeAWriterData?.becomeAWriter?.metaDescription,
    metaImage: becomeAWriterData?.becomeAWriter?.metaImage,
    isLoading,
  }
}

export default useBecomeAWriterQuery
