/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom'
import { MEDIA_URL } from '../../consts'
import { Article } from '../../types/article.types'
import RedLabel from '../red-label/red-label.component'

import './hero.styles.css'

const skeletonLoopArray = [...Array(3)]

export interface HeroProps {
  image?: string
  articles?: Pick<Article, 'id' | 'title' | 'urlKey' | 'category'>[]
  isLoading?: boolean
}

const Hero = ({ image, articles, isLoading }: HeroProps): JSX.Element => {
  const { mainArticle, smallArticles } = useMemo(() => {
    if (articles?.length) {
      return { mainArticle: articles[0], smallArticles: articles.slice(1, 4) }
    }
    return { mainArticle: null, smallArticles: [] }
  }, [articles])

  return (
    <section className='hero-section page-padding'>
      <img
        alt='الصورة الرئسية'
        className='hero-section__image'
        src={`${MEDIA_URL}resized/1900x1266/${image}`}
        srcSet={`${MEDIA_URL}resized/415x580/${image} 415w, ${MEDIA_URL}resized/1900x1266/${image} 1900w`}
      />
      <div className='hero-section__container centered-container'>
        <article className='hero-section__main-article'>
          {mainArticle && !isLoading ? (
            <>
              <RedLabel
                label={mainArticle.category.title}
                to={`/category/${mainArticle.category?.urlKey}`}
                customClassName='hero-section__main-article-label'
              />
              <Link
                title={mainArticle.title}
                to={`/article/${mainArticle.urlKey}`}
                className='hero-section__main-article-link'>
                <h1>{mainArticle.title}</h1>
              </Link>
            </>
          ) : (
            <>
              <Skeleton containerClassName='hero-section__main-article-label-skeleton' />
              <span className='hero-section__main-article-link'>
                <h1>
                  <Skeleton count={2} containerClassName='hero-section__main-article-link-skeleton' />
                </h1>
              </span>
            </>
          )}
        </article>
        {smallArticles?.length && (
          <div className='hero-section__small-articles-container'>
            <ul className='hero-section__small-articles-list'>
              {smallArticles?.length && !isLoading
                ? smallArticles.map((article) => (
                    <li key={`hero-small-articles-${article.id}`}>
                      <article className='hero-section__small-article' key={`hero-article-${article.id}`}>
                        <RedLabel
                          label={article.category.title}
                          to={`/category/${article.category?.urlKey}`}
                          customClassName='hero-section__small-article-label'
                        />
                        <Link
                          title={article.title}
                          to={`/article/${article.urlKey}`}
                          className='hero-section__small-article-link'>
                          <h1>{article.title}</h1>
                        </Link>
                      </article>
                    </li>
                  ))
                : skeletonLoopArray.map((_, index) => (
                    <li>
                      <article className='hero-section__small-article' key={`hero-article-skeleton-${index}`}>
                        <Skeleton containerClassName='hero-section__small-article-label-skeleton' />
                        <span className='hero-section__small-article-link'>
                          <h1>
                            <Skeleton containerClassName='hero-section__small-article-link-skeleton' />
                          </h1>
                        </span>
                      </article>
                    </li>
                  ))}
            </ul>
          </div>
        )}
      </div>
    </section>
  )
}

export default Hero
