import React from 'react'
import { Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { EyeIcon } from '@qesheq/qesheq-ui'

import { Article } from '../../../types/article.types'
import { Category } from '../../../types/category.types'
import { Writer } from '../../../types/writer.types'
import { formatDate } from '../../../utils'
import RedLabel from '../../red-label/red-label.component'

import './article-head.styles.css'

interface ArticleHeadProps {
  title?: Article['title']
  date?: Article['date']
  category?: Pick<Category, 'urlKey' | 'title'>
  writer?: Pick<Writer, 'urlKey' | 'fullname'>
  views?: Article['views']
  isLoading: boolean
}

const ArticleHead = (props: ArticleHeadProps): JSX.Element => {
  const { title, date, category, writer, views, isLoading } = props

  return (
    <header className='article-head page-padding'>
      <div className='article-head__container centered-container'>
        {isLoading ? (
          <Skeleton containerClassName='article-head__title article-head__title-skeleton' />
        ) : (
          <h1 className='article-head__title'>{title}</h1>
        )}

        {isLoading ? (
          <Skeleton containerClassName='article-head__info-list article-head__info-list-skeleton' />
        ) : (
          <div className='article-head__info-list '>
            {category && (
              <RedLabel
                label={category.title}
                to={`/category/${category.urlKey}`}
                customClassName='article-head__info article-head__category'
              />
            )}
            {date && <time className='article-head__info article-head__date'>{formatDate(date)}</time>}
            {writer && (
              <Link
                title={writer.fullname}
                to={`/writer/${writer.urlKey}`}
                className='article-head__info article-head__writer'>
                {writer.fullname}
              </Link>
            )}
            <span className='article-head__info article-head__views'>
              {views || 0}
              <EyeIcon className='article-head__views-icon' />
            </span>
          </div>
        )}
      </div>
    </header>
  )
}

export default ArticleHead
