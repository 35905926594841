import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import React, { ReactNode } from 'react'

import { LayoutDataProvider } from './context/layout-data.context'
import { GraphQLProvider } from './providers/graphql'

const AppWrappers = ({
  children,
  client,
}: {
  children: ReactNode
  client?: ApolloClient<NormalizedCacheObject>
}): JSX.Element => (
  <GraphQLProvider client={client}>
    <LayoutDataProvider>{children}</LayoutDataProvider>
  </GraphQLProvider>
)

export default AppWrappers
