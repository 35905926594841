import React from 'react'
import { HelmetMetas, MiniHero, WritersListing } from '../../components'
import { useWritersQuery } from '../../hooks'

import './writers.styles.css'

const WritersContainer = (): JSX.Element => {
  const { bannerImage, writers, metaTitle, metaDescription, metaImage, isLoading, isLoadingMore, hasMore, loadMore } =
    useWritersQuery({
      pageSize: 9,
    })

  return (
    <>
      <HelmetMetas title={metaTitle} description={metaDescription} image={metaImage} url='writers' />
      <MiniHero title='الكتّاب' image={bannerImage} crumbs={[{ label: 'الرئيسية', link: '/' }]} />
      <WritersListing
        listKey='writers'
        writers={writers}
        isLoading={isLoading || isLoadingMore}
        hasMore={hasMore}
        loadMore={loadMore}
      />
    </>
  )
}

export default WritersContainer
