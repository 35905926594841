import React, { useMemo, useState } from 'react'
import {
  Button,
  EnvelopeSimpleIcon,
  FacebookIcon,
  ShareIcon,
  TwitterLogoIcon,
  WhatsappLogoIcon,
} from '@qesheq/qesheq-ui'
import { Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { Article } from '../../types/article.types'
import { emailShareLink, facebookShareLink, formatDate, twitterShareLink, whatsappShareLink } from '../../utils'
import RedLabel from '../red-label/red-label.component'
import { BASE_URL, MEDIA_URL } from '../../consts'

export type ListingArticleInterface = Pick<
  Article,
  'id' | 'title' | 'urlKey' | 'category' | 'image' | 'writer' | 'smallDescription' | 'date'
>

interface ListingArticleProps {
  article?: ListingArticleInterface
  customArticleClassName?: string
  elementRef?: ((instance: HTMLElement | null) => void) | React.RefObject<HTMLElement> | null | undefined
}

const ListingArticle = ({ article, customArticleClassName, elementRef }: ListingArticleProps): JSX.Element => {
  const [isShareMenuOpen, setIsShareMenuOpen] = useState(false)

  const toggleShareMenu = (): void => {
    setIsShareMenuOpen((prevState) => !prevState)
  }

  const shareEmailLink = useMemo(() => emailShareLink(article?.urlKey), [article?.urlKey])
  const shareFacebookLink = useMemo(() => facebookShareLink(article?.urlKey), [article?.urlKey])
  const shareTwitterLink = useMemo(() => twitterShareLink(article?.urlKey), [article?.urlKey])
  const shareWhatsappLink = useMemo(() => whatsappShareLink(article?.urlKey), [article?.urlKey])

  return (
    <article className={`articles-listing__article ${customArticleClassName || ''}`} ref={elementRef}>
      <div className={`articles-listing__article-content ${!article && 'articles-listing__article-content--skeleton'}`}>
        <div className='article-listing__article-content-top'>
          <div className='articles-listing__article-frame'>
            {article ? (
              <>
                <Link to={`/article/${article.urlKey}`} title={article.title}>
                  <img
                    alt={article.title}
                    className='articles-listing__article-image'
                    src={`${MEDIA_URL}resized/394x245/${article.image}`}
                    sizes='(min-width: 901px) calc(33.33vw - 40px),
                             (min-width: 536px) calc(50vw - 30px), 
                             calc(100vw - 20px)'
                    srcSet={`${MEDIA_URL}resized/335x209/${article.image} 335w,${MEDIA_URL}resized/394x245/${article.image} 394w,${MEDIA_URL}resized/788x490/${article.image} 788w`}
                  />
                </Link>
                <RedLabel
                  label={article.category.title}
                  to={`/category/${article.category?.urlKey}`}
                  customClassName='articles-listing__article-category'
                />
              </>
            ) : (
              <Skeleton containerClassName='articles-listing__article-image' />
            )}
          </div>

          {article ? (
            <>
              <Link title={article.title} to={`/article/${article.urlKey}`} className='articles-listing__article-title'>
                <h1>{article.title}</h1>
              </Link>

              <Link
                title={article.title}
                to={`/article/${article.urlKey}`}
                className='articles-listing__article-description'>
                {article.smallDescription}
              </Link>
            </>
          ) : (
            <>
              <Skeleton containerClassName='articles-listing__article-title' />
              <Skeleton containerClassName='articles-listing__article-description' count={2} />
            </>
          )}
        </div>
        <div
          className={`articles-listing__article-content-bottom ${
            !article && 'articles-listing__article-content-bottom--skeleton'
          }`}>
          {article ? (
            <>
              <span className='articles-listing__article-date'>{formatDate(article.date)}</span>

              <Link
                title={article.writer.fullname}
                to={`/writer/${article.writer.urlKey}`}
                className='articles-listing__article-writer'>
                {article.writer.fullname}
              </Link>
            </>
          ) : (
            <>
              <Skeleton className='articles-listing__article-date' />
              <Skeleton className='articles-listing__article-writer' />
            </>
          )}
        </div>
        {article && (
          <>
            <ul
              className={`articles-listing__article-share-list ${
                isShareMenuOpen ? 'articles-listing__article-share-list--visible' : ''
              }`}>
              <li className='articles-listing__share-toggle'>
                <Button
                  type='button'
                  label='القائمة'
                  layout='icon'
                  icon={<ShareIcon className='articles-listing__share-icon articles-listing__share-toggle-icon' />}
                  onClick={toggleShareMenu}
                />
              </li>
              <li className='articles-listing__share-email'>
                <a
                  title='Email'
                  href={shareEmailLink}
                  target='_blank'
                  rel='noreferrer'
                  className='articles-listing__share-link'>
                  <EnvelopeSimpleIcon className='articles-listing__share-icon articles-listing__email-icon' />
                </a>
              </li>
              <li className='articles-listing__share-facebook'>
                <a
                  title='Email'
                  href={shareFacebookLink}
                  target='_blank'
                  rel='noreferrer'
                  className='articles-listing__share-link'>
                  <FacebookIcon className='articles-listing__share-icon articles-listing__facebook-icon' />
                </a>
              </li>
              <li className='articles-listing__share-twitter'>
                <a
                  title='Email'
                  href={shareTwitterLink}
                  target='_blank'
                  rel='noreferrer'
                  className='articles-listing__share-link'>
                  <TwitterLogoIcon className='articles-listing__share-icon articles-listing__twitter-icon' />
                </a>
              </li>
              <li className='articles-listing__share-whatsapp'>
                <a
                  title='Email'
                  href={shareWhatsappLink}
                  target='_blank'
                  rel='noreferrer'
                  className='articles-listing__share-link'>
                  <WhatsappLogoIcon className='articles-listing__share-icon articles-listing__whatsapp-icon' />
                </a>
              </li>
            </ul>
            <span
              role='button'
              className={`articles-listing__article-overlay ${
                isShareMenuOpen ? 'articles-listing__article-overlay--visible' : ''
              }`}
              onClick={toggleShareMenu}
              onKeyPress={toggleShareMenu}
              aria-label={article.title}
              tabIndex={0}
            />
          </>
        )}
      </div>
    </article>
  )
}

export default ListingArticle
