import React, { ReactNode } from 'react'
import { Breadcrumb } from '../breadcrumbs/breadcrumbs.component'
import { Breadcrumbs } from '..'
import { MEDIA_URL } from '../../consts'

import './mini-hero.styles.css'

interface MiniHeroProps {
  image?: string
  title?: string
  crumbs?: Breadcrumb[]
  children?: ReactNode
}
const MiniHero = (props: MiniHeroProps): JSX.Element => {
  const { image, title = '', crumbs, children } = props
  return (
    <section className='mini-hero page-padding'>
      {image && (
        <img
          src={`${MEDIA_URL}/resized/1600x300/${image}`}
          alt={title}
          srcSet={`${MEDIA_URL}resized/767x240/${image} 767w, ${MEDIA_URL}resized/1600x300/${image} 1600w`}
          className='mini-hero__image'
        />
      )}
      <div className='mini-hero__content'>
        {title && <h1 className='mini-hero__title'>{title}</h1>}
        {crumbs && <Breadcrumbs customClassName='mini-hero__breadcrumbs' crumbs={crumbs} current={{ label: title }} />}
      </div>
      {children}
    </section>
  )
}

export default MiniHero
