import React from 'react'
import { Error } from '../../components'

import './page-not-found.styles.css'

const error = {
  status: 404,
  message: 'الصفحة غير موجودة',
}

const PageNotFoundContainer = (): JSX.Element => <Error error={error} />

export default PageNotFoundContainer
