import React, { useCallback, useRef } from 'react'
import ListingCategory, { ListingCategoryType } from './listing-category.component'

import './categories-listing.styles.css'

interface CategoriesListingProps {
  customContainerClassName?: string
  customCategoryClassName?: string
  categories?: ListingCategoryType[]
  loadMore: () => void
  hasMore: boolean
  listKey: string
  isLoading: boolean
}

const CategoriesListing = (props: CategoriesListingProps): JSX.Element => {
  const {
    categories,
    isLoading,
    loadMore,
    hasMore,
    listKey,
    customContainerClassName = '',
    customCategoryClassName,
  } = props

  const observer = useRef<IntersectionObserver>()
  const lastCategoriesReference = useCallback(
    (element: HTMLElement | null) => {
      if (!loadMore || isLoading) {
        return
      }
      if (observer.current) {
        observer.current.disconnect()
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && !isLoading) {
          loadMore()
        }
      })
      if (element) observer?.current?.observe(element)
    },
    [isLoading, loadMore, hasMore]
  )

  return (
    <section className={`categories-listing ${customContainerClassName}`}>
      {categories?.length &&
        categories.map((category, index) => (
          <ListingCategory
            category={category}
            customClassName={customCategoryClassName}
            elementRef={index + 1 === categories.length ? lastCategoriesReference : undefined}
            key={`listing-category-${listKey}-${category.urlKey}`}
          />
        ))}
    </section>
  )
}

export default CategoriesListing
