import React from 'react'
import { Link } from 'react-router-dom'
import { DoubleArrowIcon } from '@qesheq/qesheq-ui'

import './breadcrumbs.styles.css'

export interface Breadcrumb {
  label: string
  link: string
}

interface BreadcrumbsProps {
  crumbs: Breadcrumb[]
  current?: Partial<Breadcrumb>
  customClassName?: string
}

const Breadcrumbs = ({ crumbs, current, customClassName }: BreadcrumbsProps): JSX.Element => (
  <ul className={`breadcrumbs-container ${customClassName || ''}`}>
    {crumbs.map((crumb, index) => (
      <li
        // eslint-disable-next-line react/no-array-index-key
        key={`breadcrumb-${index}`}>
        <Link title={crumb.label} to={crumb.link} className='breadcrumb-link'>
          {crumb.label}
        </Link>
        {(current || index + 1 < crumbs.length) && (
          <DoubleArrowIcon className='breadcrumbs-container__arrows-seperator' />
        )}
      </li>
    ))}
    {current && <li>{current?.label}</li>}
  </ul>
)

export default Breadcrumbs
