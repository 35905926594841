import React from 'react'
import { ContactForm, MiniHero, HelmetMetas } from '../../components'
import { useContactQuery } from '../../hooks'

import './contact.styles.css'

const ContactContainer = (): JSX.Element => {
  const { email, image, metaTitle, metaDescription, metaImage } = useContactQuery()

  return (
    <>
      <HelmetMetas title={metaTitle} description={metaDescription} image={metaImage} url='contact' />

      <MiniHero image={image} title='إتصل بنا' crumbs={[{ label: 'الرئيسية', link: '/' }]}>
        {email && (
          <a title={email} href={`mailto:${email}`} className='contact-page__email'>
            {email}
          </a>
        )}
      </MiniHero>

      <ContactForm />
    </>
  )
}

export default ContactContainer
