import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button } from '@qesheq/qesheq-ui'

import HelmetMetas from '../helmet-metas'
import './error.styles.css'
import { ServerStatusTag } from '..'

interface ErrorBoundaryProps {
  error?: { status?: number; message?: string }
  onReset?: () => void
  resetLabel?: string
  resetLink?: string
}

const Error = (props?: ErrorBoundaryProps): JSX.Element => {
  const error: any = props?.error
  const location = useLocation()

  const [errorDetails] = useState({
    status: error?.status || 500,
    message: error?.data?.message || error?.message || 'هناك خطأ ما',
  })

  return (
    <div className='error-component page-padding flex-container align-items-center '>
      <HelmetMetas title='كشك - الصفحة غير موجودة' url={location?.pathname ?? ''} />
      {/* DO NOT REMOVE THIS TAG. Used for SSR status detection. */}
      <ServerStatusTag status={errorDetails.status} />
      <div className='error-component__container centered-container flex-container align-items-center'>
        <h1 className='error-component__title'>{errorDetails?.status}</h1>
        <h2 className='error-component__subtitle'>{errorDetails?.message}</h2>
        {(props?.onReset || props?.resetLink) && (
          <Button
            type={props?.onReset ? 'button' : 'link'}
            label={props?.resetLabel || 'الصفحة الرئسية'}
            onClick={props?.onReset}
            href={props?.resetLink}
            external
            customClassName='error-component__reset-button'
          />
        )}
      </div>
    </div>
  )
}

export default Error
