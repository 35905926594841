import React, { useEffect, useState } from 'react'

import { ADSENSE_CLIENT_ID } from '../../consts'

import './ad-unit.styles.css'

export type AdFormat = 'auto' | 'rectangle' | 'fluid' | 'horizontal' | 'square' | 'vertical'
export interface AdFormatBreakpoint {
  minBreakpoint: number
  format: AdFormat
}

interface AdUnitProps {
  adName: string
  slotId: string
  adLayout: 'in-article'
  adFormats: AdFormatBreakpoint[]
  fullWidthResponsive?: 'true' | 'false'
  customClassName?: string
  style?: any
}

const resolveAdFormat = (adFormats: AdFormatBreakpoint[]): AdFormat => {
  if (typeof window !== 'undefined') {
    const adFormat = adFormats.reduce((acc: AdFormatBreakpoint | undefined, af) => {
      if (!acc) {
        return af
      }
      if (window.innerWidth > af.minBreakpoint && af.minBreakpoint > acc.minBreakpoint) {
        return af
      }
      return acc
    }, undefined)

    return adFormat?.format || 'auto'
  }

  return 'auto'
}

const AdUnit = ({
  slotId,
  adLayout,
  adFormats,
  fullWidthResponsive,
  adName,
  customClassName = '',
  style = { display: 'block' },
}: AdUnitProps): JSX.Element => {
  const [format, setFormat] = useState<AdFormat>('auto')

  useEffect(() => {
    setFormat(resolveAdFormat(adFormats))
    setTimeout(() => {
      if (typeof window !== 'undefined' && (window as any).adsbygoogle) {
        try {
          ;((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({})
        } catch (e) {
          console.error('Adsense script error', e)
        }
      }
    }, 200)
  }, [])

  useEffect(() => {
    const handleResize = (): void => {
      setFormat(resolveAdFormat(adFormats))
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className={`ad-unit ${customClassName}`}>
      <ins
        className='adsbygoogle'
        style={style}
        data-ad-client={ADSENSE_CLIENT_ID}
        data-ad-layout={adLayout}
        data-ad-slot={slotId}
        data-ad-format={format}
        data-full-width-responsive={fullWidthResponsive}
      />
    </div>
  )
}

export default AdUnit
