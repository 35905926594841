import React from 'react'
import { useParams } from 'react-router-dom'
import { ArticlesListing, HelmetMetas, MiniHero } from '../../components'
import { useCategoryDetailsQuery } from '../../hooks'

import './category-details.styles.css'

const CategoryDetailsContainer = (): JSX.Element => {
  const { urlKey } = useParams()

  const { bannerImage, pageTitle, smallDescription, image, articles, isLoading, hasMore, loadMore } =
    useCategoryDetailsQuery({
      urlKey: urlKey!,
      pageSize: 9,
    })
  return (
    <>
      <HelmetMetas
        title={`${pageTitle} - كشك`}
        description={smallDescription}
        image={image}
        url={`category/${urlKey}`}
      />

      <MiniHero
        image={bannerImage}
        title={pageTitle}
        crumbs={[
          { label: 'الرئيسية', link: '/' },
          { label: 'المواضيع', link: '/categories' },
        ]}
      />
      <ArticlesListing
        articles={articles}
        isLoading={isLoading}
        listKey='category-details'
        customClassName='category-details-page__listing'
        loadMoreArticles={loadMore}
        hasMoreArticles={hasMore}
      />
    </>
  )
}

export default CategoryDetailsContainer
