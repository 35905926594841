import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import '@qesheq/qesheq-ui/dist/css/utils.css'
import '@qesheq/qesheq-ui/dist/css/base.css'

import './App.css'
import { MainRouter } from './routers'

function App(): JSX.Element {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <div className='main-container'>
      <MainRouter />
    </div>
  )
}

export default App
