import React, { useEffect, useMemo, useState } from 'react'
import {
  EnvelopeSimpleIcon,
  FacebookIcon,
  TwitterLogoIcon,
  WhatsappLogoIcon,
  LinkedinIcon,
  QEditor,
} from '@qesheq/qesheq-ui'
import Skeleton from 'react-loading-skeleton'
import '@qesheq/qesheq-ui/dist/css/q-editor.styles.css'
import 'react-loading-skeleton/dist/skeleton.css'

import { ARTICLE_DESCRIPTION_AD_SLOT_ID, MEDIA_URL } from '../../../consts'
import { Article } from '../../../types/article.types'
import { AdUnit } from '../..'
import {
  emailShareLink,
  facebookShareLink,
  linkedinShareLink,
  twitterShareLink,
  whatsappShareLink,
} from '../../../utils'

import './article-main.styles.css'
import { AdFormatBreakpoint } from '../../ad-unit/ad-unit.component'

const descriptionAdFormats: AdFormatBreakpoint[] = [
  { minBreakpoint: 0, format: 'horizontal' },
  { minBreakpoint: 1001, format: 'rectangle' },
]

interface ArticleHeadProps {
  title?: Article['title']
  image?: Article['image']
  urlKey?: Article['urlKey']
  description?: Article['description']
  tags: Article['tags']
  isLoading: boolean
  customClassName?: string
}

const ArticleMain = (props: ArticleHeadProps): JSX.Element => {
  const { title, image, urlKey, description, tags, isLoading, customClassName = '' } = props

  const [isMobileShareVisible, setIsMobileShareVisible] = useState(true)
  const [lastScrollPosition, setLastScrollPosition] = useState(0)

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const handleScroll = (): void => {
      setIsMobileShareVisible(window ? window.scrollY < lastScrollPosition : false)
      setLastScrollPosition(window?.scrollY ?? 0)
    }

    if (window) {
      window.addEventListener('scroll', handleScroll, { passive: true })

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [lastScrollPosition])

  const shareEmailLink = useMemo(() => emailShareLink(urlKey), [urlKey])
  const shareFacebookLink = useMemo(() => facebookShareLink(urlKey), [urlKey])
  const shareTwitterLink = useMemo(() => twitterShareLink(urlKey), [urlKey])
  const shareWhatsappLink = useMemo(() => whatsappShareLink(urlKey), [urlKey])
  const shareLinkedinLink = useMemo(() => linkedinShareLink(urlKey), [urlKey])

  return (
    <main className={`article-main ${customClassName}`}>
      <div className='article-main__media-container'>
        <div className={`article-main__share-list ${isMobileShareVisible ? `article-main__share-list--visible` : ''}`}>
          <a
            title='Share to facebook'
            href={shareFacebookLink}
            target='_blank'
            rel='noreferrer'
            className='article-main__share-item article-main__share-facebook'>
            <FacebookIcon className='article-main__share-icon article-main__facebook-icon' />
          </a>
          <a
            title='Share to Twitter'
            href={shareTwitterLink}
            target='_blank'
            rel='noreferrer'
            className='article-main__share-item article-main__share-twitter'>
            <TwitterLogoIcon className='article-main__share-icon article-main__twitter-icon' />
          </a>
          <a
            title='Share to Linkedin'
            href={shareLinkedinLink}
            target='_blank'
            rel='noreferrer'
            className='article-main__share-item article-main__share-linkedin'>
            <LinkedinIcon className='article-main__share-icon article-main__linkedin-icon' />
          </a>
          <a
            title='Share with Whatsapp'
            href={shareWhatsappLink}
            target='_blank'
            rel='noreferrer'
            className='article-main__share-item article-main__share-whatsapp'>
            <WhatsappLogoIcon className='article-main__share-icon article-main__whatsapp-icon' />
          </a>
          <a
            title='Share via email'
            href={shareEmailLink}
            target='_blank'
            rel='noreferrer'
            className='article-main__share-item article-main__share-email'>
            <EnvelopeSimpleIcon className='article-main__share-icon article-main__email-icon' />
          </a>
        </div>

        <figure className='article-main__frame'>
          {isLoading ? (
            <Skeleton count={1} className='article-main__image article-main__image-skeleton' />
          ) : (
            <img
              alt={title}
              className='article-main__image'
              sizes='(min-width: 951px) calc(100vw - 430px), 
                          (min-width: 768px) calc(100vw - 40px), 
                          calc(100vw - 20px)'
              src={`${MEDIA_URL}resized/885x412/${image}`}
              srcSet={`${MEDIA_URL}resized/395x200/${image} 395w,${MEDIA_URL}resized/885x412/${image} 885w,${MEDIA_URL}resized/1328x824/${image} 1328w`}
            />
          )}
        </figure>
      </div>
      <div className='article-main__description'>
        {isLoading ? (
          <>
            <Skeleton
              count={5}
              containerClassName='article-main__description-skeleton-container'
              className='article-main__description-skeleton'
            />
            <Skeleton
              count={8}
              containerClassName='article-main__description-skeleton-container'
              className='article-main__description-skeleton'
            />
          </>
        ) : (
          <QEditor
            value={description}
            layout='read-only'
            customClassName='article-main__q-editor'
            placeholder='PLACEHOLDER'
            readOnly>
            <div className='article-main__editor-ad-wrapper'>
              <AdUnit
                adName='article-description'
                adFormats={descriptionAdFormats}
                adLayout='in-article'
                slotId={ARTICLE_DESCRIPTION_AD_SLOT_ID}
                customClassName='article-main__editor-ad'
                style={{ display: 'block', textAlign: 'center' }}
              />
            </div>
          </QEditor>
        )}
      </div>
      {tags?.length && (
        <ul className='article-main__tags-list'>
          {tags.map((tag) => (
            <li className='article-main__tag-item' key={`article-tag-${tag.id}`}>
              {tag.title}
            </li>
          ))}
        </ul>
      )}
    </main>
  )
}

export default ArticleMain
