import { gql, useMutation } from '@apollo/client'
import { useCallback } from 'react'

const mutation = gql`
  mutation advertise($data: AdvertiseInput!) {
    advertise(data: $data) {
      id
      dateCreated
    }
  }
`

interface SendAdvertisePayload {
  name: string
  company: string
  phone?: string
  email: string
  budget?: string
  duration: string
  message: string
}

interface AdvertiseMutationInterface {
  sendAdvertise: (payload: SendAdvertisePayload) => void
  reset: () => void
  error?: string
  isCreated: boolean
  isLoading: boolean
}

const useAdvertiseMutation = (): AdvertiseMutationInterface => {
  const [postAdvertise, { loading: isLoading, error: mutationError, data, reset }] = useMutation(mutation)

  const sendAdvertise = useCallback(
    (payload: SendAdvertisePayload) => {
      postAdvertise({ variables: { data: payload } })
    },
    [postAdvertise]
  )

  return {
    sendAdvertise,
    reset,
    isLoading,
    isCreated: Boolean(data?.advertise?.dateCreated),
    error: mutationError?.message,
  }
}

export default useAdvertiseMutation
