import { gql, useQuery } from '@apollo/client'
import { Descendant } from '@qesheq/qesheq-ui'

const query = gql`
  query about {
    about {
      image
      description
      metaTitle
      metaDescription
      metaImage
    }
  }
`

interface AboutQueryInterface {
  image?: string
  description?: Descendant[]
  metaTitle?: string
  metaDescription?: string
  metaImage?: string
  isLoading: boolean
}

const useAboutQuery = (): AboutQueryInterface => {
  const { loading: isLoading, data: aboutData } = useQuery(query)

  return {
    description: aboutData?.about?.description,
    image: aboutData?.about?.image,
    metaTitle: aboutData?.about?.metaTitle,
    metaDescription: aboutData?.about?.metaDescription,
    metaImage: aboutData?.about?.metaImage,
    isLoading,
  }
}

export default useAboutQuery
