import { gql, useMutation } from '@apollo/client'
import { useCallback } from 'react'

const mutation = gql`
  mutation becomeAWriter($data: BecomeAWriterInput!) {
    becomeAWriter(data: $data) {
      id
      dateCreated
    }
  }
`

interface SendBecomeAWriterPayload {
  name: string
  gender: string
  country: string
  email: string
  message: string
}

interface BecomeAWriterMutationInterface {
  sendBecomeAWriter: (payload: SendBecomeAWriterPayload) => void
  reset: () => void
  error?: string
  isCreated: boolean
  isLoading: boolean
}

const useBecomeAWriterMutation = (): BecomeAWriterMutationInterface => {
  const [postBecomeAWriter, { loading: isLoading, error: mutationError, data, reset }] = useMutation(mutation)

  const sendBecomeAWriter = useCallback(
    (payload: SendBecomeAWriterPayload) => {
      postBecomeAWriter({ variables: { data: payload } })
    },
    [postBecomeAWriter]
  )

  return {
    sendBecomeAWriter,
    reset,
    isLoading,
    isCreated: Boolean(data?.becomeAWriter?.dateCreated),
    error: mutationError?.message,
  }
}

export default useBecomeAWriterMutation
