import React from 'react'
import { Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { ARTICLE_SIDEBAR_AD_1_SLOT_ID, ARTICLE_SIDEBAR_AD_2_SLOT_ID, MEDIA_URL } from '../../../consts'
import { Article } from '../../../types/article.types'
import { Category } from '../../../types/category.types'
import { formatDate } from '../../../utils'
import { AdUnit } from '../..'
import { AdFormatBreakpoint } from '../../ad-unit/ad-unit.component'

import './article-sidebar.styles.css'

const skeletonLoopArray = [...Array(4)]

const sidebarAd1Formats: AdFormatBreakpoint[] = [
  { minBreakpoint: 0, format: 'auto' },
  { minBreakpoint: 951, format: 'rectangle' },
]
const sidebarAd2Formats: AdFormatBreakpoint[] = [
  { minBreakpoint: 0, format: 'auto' },
  { minBreakpoint: 951, format: 'vertical' },
]

interface ArticleSidebarProps {
  categories?: Pick<Category, 'id' | 'urlKey' | 'image' | 'title'>[]
  relatedArticles?: Pick<Article, 'id' | 'urlKey' | 'title' | 'date' | 'image' | 'writer'>[]
  customClassName?: string
  isLoading?: boolean
}

const skeletonArticles = (): JSX.Element => (
  <>
    {skeletonLoopArray.map((_, index) => (
      <article
        className='article-sidebar__article'
        // eslint-disable-next-line react/no-array-index-key
        key={`sidebar-article-skeleton-${index}`}>
        <Skeleton
          className='article-sidebar__article-frame article-sidebar__article-frame-skeleton'
          containerClassName='article-sidebar__article-frame-skeleton-container'
        />
        <div className='article-sidebar__article-content'>
          <Skeleton className='article-sidebar__article-title article-sidebar__article-title-skeleton' />

          <Skeleton className='article-sidebar__article-info article-sidebar__article-info-skeleton' />
        </div>
      </article>
    ))}
  </>
)

const skeletonCategories = (): JSX.Element => (
  <>
    {skeletonLoopArray.map((_, index) => (
      <article
        className='article-sidebar__category'
        // eslint-disable-next-line react/no-array-index-key
        key={`sidebar-category-skeleton-${index}`}>
        <Skeleton className='article-sidebar__category-frame article-sidebar__category-frame-skeleton' />

        <Skeleton className='article-sidebar__category-title article-sidebar__category-title-skeleton' />
      </article>
    ))}
  </>
)

const ArticleSidebar = (props: ArticleSidebarProps): JSX.Element => {
  const { relatedArticles = [], categories = [], customClassName = '', isLoading = false } = props

  return (
    <aside className={`article-sidebar ${customClassName}`}>
      <div className='article-sidebar__box article-sidebar__ad-box'>
        <div className='article-sidebar__ad-wrapper'>
          <AdUnit
            adName='article-sidebar-1'
            adFormats={sidebarAd1Formats}
            adLayout='in-article'
            slotId={ARTICLE_SIDEBAR_AD_1_SLOT_ID}
            customClassName='article-sidebar__ad'
            fullWidthResponsive='true'
          />
        </div>
      </div>

      <div className='article-sidebar__box article-sidebar__related-articles-box '>
        <span className='article-sidebar__related-articles-title'>مقالات ذات صلة</span>
        {isLoading
          ? skeletonArticles()
          : relatedArticles?.map((article, index) => (
              <article className='article-sidebar__article' key={`sidebar-article-${article.id}`}>
                <Link
                  title={article.title}
                  to={`/article/${article.urlKey}`}
                  className='article-sidebar__article-frame-link'>
                  <figure className='article-sidebar__article-frame'>
                    <img
                      alt={article.title}
                      className='article-sidebar__article_image'
                      src={`${MEDIA_URL}resized/${index === 0 ? '335x209' : '130x91'}/${article.image}`}
                      srcSet={`${MEDIA_URL}resized/${index === 0 ? '335x209' : '130x91'}/${
                        article.image
                      }, ${MEDIA_URL}resized/${index === 0 ? '788x490' : '260x182'}/${
                        article.image
                      } 2x,${MEDIA_URL}resized/${index === 0 ? '788x490' : '260x182'}/${article.image} 3x`}
                    />
                  </figure>
                </Link>
                <div className='article-sidebar__article-content'>
                  <h1 className='article-sidebar__article-title'>{article.title}</h1>
                  <div className='article-sidebar__article-info'>
                    <time className='article-sidebar__article-date'>{formatDate(article.date)}</time>
                    <Link
                      title={article.writer.fullname}
                      to={`/writer/${article.writer.urlKey}`}
                      className='article-sidebar__article-writer'>
                      {article.writer.fullname}
                    </Link>
                  </div>
                </div>
              </article>
            ))}
      </div>

      <div className='article-sidebar__box article-sidebar__ad-box'>
        <div className='article-sidebar__ad-wrapper'>
          <AdUnit
            adName='article-sidebar-2'
            adFormats={sidebarAd2Formats}
            adLayout='in-article'
            slotId={ARTICLE_SIDEBAR_AD_2_SLOT_ID}
            customClassName='article-sidebar__ad'
            fullWidthResponsive='true'
          />
        </div>
      </div>

      <div className='article-sidebar__box article-sidebar__related-categories'>
        <span className='article-sidebar__related-categories-title'>مواضيع ذات صلة</span>
        <div className='article-sidebar__categories-list'>
          {isLoading
            ? skeletonCategories()
            : categories.map((category) => (
                <article className='article-sidebar__category' key={`sidebar-category-${category.id}`}>
                  <Link title={category.title} to={`/category/${category.urlKey}`}>
                    <figure className='article-sidebar__category-frame'>
                      <img
                        src={`${MEDIA_URL}resized/294x196/${category.image}`}
                        alt={category.title}
                        className='article-sidebar__category-image'
                      />
                    </figure>
                  </Link>
                  <Link title={category.title} to={`/category/${category.urlKey}`}>
                    <h1 className='article-sidebar__category-title'>{category.title}</h1>
                  </Link>
                </article>
              ))}
        </div>
      </div>
    </aside>
  )
}

export default ArticleSidebar
