import React from 'react'
import { Helmet } from 'react-helmet-async'
import { BASE_URL, MEDIA_URL } from '../consts'

interface HelmetMetasInterface {
  title?: string
  description?: string
  image?: string
  url: string
}

const HelmetMetas = ({
  title = 'كشك',
  description = 'منصتك لقراءة ممتعة ومشاركة فريدة. منبر من الناس والى الناس.',
  image = 'qesheq-shared.jpg',
  url = '',
}: HelmetMetasInterface): JSX.Element => (
  <Helmet>
    <title>{title}</title>
    <meta name='description' content={description} />
    <meta name='author' content='Qesheq' />

    {/* Facebook  */}
    <meta property='og:type' content='Website' />
    <meta property='og:url' content={`${BASE_URL}${url}`} />
    <meta property='og:image' content={`${MEDIA_URL}resized/1200x630/${image}`} />
    <meta property='og:title' content={title} />
    <meta property='og:description' content={description} />
    <meta property='og:site_name' content='كشك' />

    {/* Twitter */}
    <meta name='twitter:card' content={`${MEDIA_URL}resized/1200x630/${image}`} />
    <meta name='twitter:site' content={`${BASE_URL}${url}`} />
    <meta name='twitter:title' content={title} />
    <meta name='twitter:description' content={description} />
    <meta name='twitter:image' content={`${MEDIA_URL}resized/1200x630/${image}`} />

    <link rel='canonical' href={`${BASE_URL}${url}`} />
  </Helmet>
)

export default HelmetMetas
