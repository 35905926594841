import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ArticlesListing, HelmetMetas, WriterHero } from '../../components'
import { useWriterDetailsQuery } from '../../hooks'

import './writer-details.styles.css'

const WriterDetailsContainer = (): JSX.Element => {
  const { urlKey } = useParams()

  const {
    coverImage,
    image,
    bio,
    rawBio,
    fullname,
    website,
    facebook,
    twitter,
    articles,
    isLoading,
    hasMore,
    loadMore,
  } = useWriterDetailsQuery({
    urlKey: urlKey!,
    pageSize: 9,
  })

  const metaDescription = useMemo(() => {
    if (!rawBio) {
      return undefined
    }
    return rawBio.length > 100 ? `${rawBio.substring(0, 97)}...` : rawBio
  }, [rawBio])

  return (
    <>
      <HelmetMetas title={`كشك - ${fullname}`} description={metaDescription} image={image} url={`writer/${urlKey}`} />

      <WriterHero
        coverImage={coverImage}
        image={image}
        bio={bio}
        fullname={fullname}
        website={website}
        facebook={facebook}
        twitter={twitter}
        isLoading={false}
      />
      <ArticlesListing
        articles={articles}
        isLoading={isLoading}
        listKey='writer-details'
        customClassName='writer-details-page__listing'
        loadMoreArticles={loadMore}
        hasMoreArticles={hasMore}
      />
    </>
  )
}

export default WriterDetailsContainer
