import React, { useEffect } from 'react'
import { Button, InputField, TextAreaField } from '@qesheq/qesheq-ui'

import { useBecomeAWriterMutation, useInputState } from '../../../hooks'
import './become-a-writer-form.styles.css'

const BecomeAWriterForm = (): JSX.Element => {
  const {
    value: fullname,
    setValue: setFullname,
    error: fullnameError,
    validate: validateFullname,
    onBlur: fullnameOnBlur,
    reset: resetFullname,
  } = useInputState({ initialValue: '', validation: { required: true } })

  const {
    value: gender,
    setValue: setGender,
    error: genderError,
    validate: validateGender,
    onBlur: genderOnBlur,
    reset: resetGender,
  } = useInputState({ initialValue: '', validation: { required: true } })

  const {
    value: country,
    setValue: setCountry,
    error: countryError,
    validate: validateCountry,
    onBlur: countryOnBlur,
    reset: resetCountry,
  } = useInputState({ initialValue: '', validation: { required: true } })

  const {
    value: email,
    setValue: setEmail,
    error: emailError,
    validate: validateEmail,
    onBlur: emailOnBlur,
    reset: resetEmail,
  } = useInputState({ initialValue: '', validation: { required: true, email: true } })

  const {
    value: message,
    setValue: setMessage,
    error: messageError,
    validate: validateMessage,
    onBlur: messageOnBlur,
    reset: resetMessage,
  } = useInputState<string>({ initialValue: '', validation: { required: true } })

  const { sendBecomeAWriter, isCreated, isLoading, error, reset: resetMutation } = useBecomeAWriterMutation()

  const handleSubmit = async (event: React.SyntheticEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()
    let isFormValid = true
    isFormValid = validateFullname() && isFormValid
    isFormValid = validateEmail() && isFormValid
    isFormValid = validateMessage() && isFormValid
    isFormValid = validateCountry() && isFormValid
    isFormValid = validateGender() && isFormValid

    if (!isLoading && isFormValid) {
      const payload = {
        name: fullname,
        email,
        gender,
        message,
        country,
      }

      sendBecomeAWriter(payload)
    }
  }

  useEffect(() => {
    if (error) {
      console.log('Error while submittion become a writer form', error)
      setTimeout(() => {
        resetMutation()
      }, 3000)
    }
  }, [error])

  useEffect(() => {
    if (isCreated) {
      setTimeout(() => {
        resetGender()
        resetCountry()
        resetEmail()
        resetMessage()
        resetFullname()
        resetMutation()
      }, 5000)
    }
  }, [isCreated])

  return (
    <div className='become-a-writer-form page-padding'>
      <div className='become-a-writer-form__container centered-container'>
        <form action='' className='become-a-writer-form__form' onSubmit={handleSubmit}>
          <InputField
            value={fullname}
            onChange={setFullname}
            errorMessage={fullnameError}
            label='الأسم'
            onBlur={fullnameOnBlur}
            customClassName='become-a-writer-form__input-field'
            isDisabled={isLoading}
          />
          <InputField
            value={country}
            onChange={setCountry}
            errorMessage={countryError}
            label='بلد الاقامة'
            onBlur={countryOnBlur}
            customClassName='become-a-writer-form__input-field'
            isDisabled={isLoading}
          />
          <InputField
            value={gender}
            onChange={setGender}
            errorMessage={genderError}
            label='الجنس'
            onBlur={genderOnBlur}
            customClassName='become-a-writer-form__input-field'
            isDisabled={isLoading}
          />
          <InputField
            value={email}
            onChange={setEmail}
            errorMessage={emailError}
            label='البريد الالكتروني'
            onBlur={emailOnBlur}
            customClassName='become-a-writer-form__input-field'
            isDisabled={isLoading}
          />
          <TextAreaField
            value={message}
            onChange={setMessage}
            errorMessage={messageError}
            label='الرسالة'
            onBlur={messageOnBlur}
            customClassName='become-a-writer-form__text-area-field'
            isDisabled={isLoading}
          />
          <Button
            label='أرسل'
            type='input'
            layout='solid'
            customClassName='become-a-writer-form__submit-button'
            isDisabled={isLoading}
          />
          <div
            className={`become-a-writer-form__error-container ${
              error ? 'become-a-writer-form__error-container--visible' : ''
            }`}>
            <span className='become-a-writer-form__error-title'>عذراً</span>
            <span className='become-a-writer-form__error-message'> حدث خطأ ما</span>
          </div>
          <div
            className={`become-a-writer-form__success-container ${
              isCreated ? 'become-a-writer-form__success-container--visible' : ''
            }`}>
            <span className='become-a-writer-form__success-title'>شكرا لك</span>
            <span className='become-a-writer-form__success-message'>لقد تم إستلام رسالتك</span>
          </div>
        </form>
      </div>
    </div>
  )
}

export default BecomeAWriterForm
