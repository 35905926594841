import React from 'react'
import { useParams } from 'react-router-dom'
import { ArticleHead, ArticleMain, ArticleSidebar, ArticlesListing, Error, HelmetMetas } from '../../components'
import useArticleQuery from '../../hooks/queries/article-query.hook'

import './article-details.styles.css'

const ArticleDetailsContainer = (): JSX.Element => {
  const { urlKey } = useParams()
  const {
    title,
    date,
    isLoading,
    category,
    description,
    smallDescription,
    image,
    relatedArticles,
    tags,
    views,
    writer,
    categories,
    articles,
    hasMore,
    isLoadingMore,
    loadMore,
  } = useArticleQuery({ urlKey: urlKey! })

  if (!isLoading && !isLoadingMore && !title) {
    return (
      <Error
        error={{
          status: 404,
          message: 'الصفحة غير موجودة',
        }}
      />
    )
  }

  return (
    <section className='article-details-page'>
      <HelmetMetas title={`${title} - كشك`} description={smallDescription} image={image} url={`article/${urlKey}`} />

      <ArticleHead title={title} date={date} isLoading={isLoading} category={category} views={views} writer={writer} />
      <div className='page-padding'>
        <div className='article-details-page__container centered-container'>
          <ArticleMain
            title={title}
            urlKey={urlKey}
            image={image}
            tags={tags}
            description={description}
            isLoading={isLoading}
            customClassName='article-details-page__article-main'
          />
          <ArticleSidebar
            customClassName='article-details-page__article-sidebar'
            relatedArticles={relatedArticles}
            categories={categories}
            isLoading={isLoading}
          />
        </div>
      </div>

      <ArticlesListing
        title='إقرأ أيضاً'
        articles={articles}
        isLoading={isLoading || isLoadingMore}
        listKey='writer-details'
        customClassName='article-details-page__listing'
        loadMoreArticles={loadMore}
        hasMoreArticles={hasMore}
      />
    </section>
  )
}

export default ArticleDetailsContainer
