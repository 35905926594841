import { gql, useMutation } from '@apollo/client'
import { useCallback } from 'react'

const mutation = gql`
  mutation contact($data: ContactInput!) {
    contact(data: $data) {
      id
      dateCreated
    }
  }
`

interface SendContactPayload {
  name: string
  phone?: string
  email: string
  message: string
}

interface ContactMutationInterface {
  sendContact: (payload: SendContactPayload) => void
  reset: () => void
  error?: string
  isCreated: boolean
  isLoading: boolean
}

const useContactMutation = (): ContactMutationInterface => {
  const [postContact, { loading: isLoading, error: mutationError, data, reset }] = useMutation(mutation)

  const sendContact = useCallback(
    (payload: SendContactPayload) => {
      postContact({ variables: { data: payload } })
    },
    [postContact]
  )

  return {
    sendContact,
    reset,
    isLoading,
    isCreated: Boolean(data?.contact?.dateCreated),
    error: mutationError?.message,
  }
}

export default useContactMutation
