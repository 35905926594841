import { gql, useQuery } from '@apollo/client'
import { Descendant } from '@qesheq/qesheq-ui'
import { useMemo } from 'react'
import { ListingArticleInterface } from '../../components/articles-listing/listing-article.component'
import { Writer } from '../../types/writer.types'

const initialQuery = gql`
  query writer($urlKey: String!, $sort: SortInput, $pagination: PaginationInput, $filters: ArticleFiltersInput) {
    writer: writerByUrlKey(urlKey: $urlKey) {
      coverImage
      image
      fullname
      bio
      rawBio
      facebook
      twitter
      website
      articles(sort: $sort, pagination: $pagination, filters: $filters) {
        total
        limit
        skip
        data {
          id
          urlKey
          title
          image
          date
          smallDescription
          category {
            urlKey
            title
          }
          writer {
            urlKey
            fullname
          }
        }
      }
    }
  }
`
interface WriterDetailsQueryProps {
  pageSize?: number
  pageNumber?: number
  urlKey: string
}
interface WriterDetailsQueryInterface {
  coverImage?: Writer['coverImage']
  image?: Writer['image']
  fullname?: Writer['fullname']
  bio?: Descendant[]
  rawBio?: Writer['rawBio']
  website?: Writer['website']
  twitter?: Writer['twitter']
  facebook?: Writer['facebook']
  articles?: ListingArticleInterface[]
  isLoading: boolean
  hasMore: boolean
  loadMore: () => void
}

const sort = {
  by: 'date',
  direction: 'desc',
}

const useWriterDetailsQuery = ({
  pageSize = 8,
  pageNumber = 1,
  urlKey,
}: WriterDetailsQueryProps): WriterDetailsQueryInterface => {
  const {
    loading: isLoading,
    data: writerData,
    fetchMore,
  } = useQuery(initialQuery, {
    variables: {
      urlKey,
      sort,
      filters: {
        isPublished: true,
      },
      pagination: {
        limit: pageSize * pageNumber,
      },
    },
    notifyOnNetworkStatusChange: true,
  })

  const data = writerData?.writer
  const hasMore = useMemo(
    () => data?.articles?.total > data?.articles?.data?.length,
    [data?.articles?.data?.length, data?.articles?.total]
  )

  const loadMore = (): void => {
    if (hasMore && !isLoading) {
      const skipSize = data?.articles?.data?.length || 0
      fetchMore({
        variables: {
          urlKey,
          sort,
          filters: {
            isPublished: true,
          },
          pagination: {
            limit: pageSize,
            skip: skipSize,
          },
        },
        updateQuery: (previousData, { fetchMoreResult }) => {
          if (!fetchMoreResult) return previousData
          return {
            writer: {
              ...previousData.writer,
              articles: {
                ...fetchMoreResult.writer.articles,
                data: [
                  ...(previousData?.writer?.articles?.data || []),
                  ...(fetchMoreResult.writer.articles?.data || []),
                ],
              },
            },
          }
        },
      })
    }
  }

  return {
    coverImage: data?.coverImage,
    image: data?.image,
    fullname: data?.fullname,
    bio: data?.bio,
    rawBio: data?.rawBio,
    website: data?.website,
    facebook: data?.facebook,
    twitter: data?.twitter,
    articles: data?.articles?.data || [],
    isLoading,
    hasMore,
    loadMore,
  }
}

export default useWriterDetailsQuery
