import React from 'react'
import { QEditor } from '@qesheq/qesheq-ui'
import '@qesheq/qesheq-ui/dist/css/q-editor.styles.css'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { HelmetMetas, MiniHero } from '../../components'
import { useAboutQuery } from '../../hooks'

import './about.styles.css'

const AboutContainer = (): JSX.Element => {
  const { description, image, metaTitle, metaDescription, metaImage, isLoading } = useAboutQuery()
  return (
    <>
      <HelmetMetas title={metaTitle} description={metaDescription} image={metaImage} url='about' />

      <MiniHero image={image} title='عن كشك' crumbs={[{ label: 'الرئيسية', link: '/' }]} />

      <div className='about-page__body page-padding'>
        <div className='about-page__description-container centered-container'>
          {isLoading ? (
            <Skeleton count={8} className='about-page__description-skeleton' />
          ) : (
            <QEditor layout='read-only' value={description} readOnly customClassName='about-page__description' />
          )}
        </div>
      </div>
    </>
  )
}

export default AboutContainer
