export const months = [
  'كانون الثاني	',
  'شباط',
  'آذار',
  'نيسان',
  'أيار',
  'حزيران',
  'تموز',
  'آب',
  'أيلول',
  'تشرين الأول	',
  'تشرين الثاني	',
  'كانون الأول	',
]

// Ad units slot ids
export const ARTICLE_DESCRIPTION_AD_SLOT_ID = '5345279226'
export const ARTICLE_SIDEBAR_AD_1_SLOT_ID = '5221936399'
export const ARTICLE_SIDEBAR_AD_2_SLOT_ID = '6055015223'
export const ARTICLE_SEPERATOR_AD_SLOT_ID = '8817620374'
export const HOME_INTRO_AD_SLOT_ID = '6907263592'
export const FEED_AD_SLOT_ID = '5469348406'

export const BASE_URL = process.env.REACT_APP_BASE_URL
export const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL
export const MEDIA_URL = process.env.REACT_APP_MEDIA_URL
export const ADSENSE_CLIENT_ID = process.env.REACT_APP_ADSENSE_CLIENT_ID
export const APP_ENV = process.env.REACT_APP_APP_ENV
export const SENTRY_ORG_ID = process.env.REACT_APP_SENTRY_ORG_ID
export const SENTRY_WEBSITE_APP_ID = process.env.REACT_APP_SENTRY_WEBSITE_APP_ID
