import React from 'react'
import { AdvertiseForm, HelmetMetas, MiniHero } from '../../components'
import { useAdvertiseQuery } from '../../hooks'

import './advertise.styles.css'

const AdvertiseContainer = (): JSX.Element => {
  const { image, metaTitle, metaDescription, metaImage } = useAdvertiseQuery()

  return (
    <>
      <HelmetMetas title={metaTitle} description={metaDescription} image={metaImage} url='advertise' />

      <MiniHero image={image} title='لاعلاناتكم' crumbs={[{ label: 'الرئيسية', link: '/' }]} />

      <AdvertiseForm />
    </>
  )
}

export default AdvertiseContainer
