import React from 'react'
import ReactDOM, { hydrateRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import './sentry'

import App from './App'
import AppWrappers from './app-wrappers'
import reportWebVitals from './reportWebVitals'

if (process.env.REACT_APP_CLIENT_ONLY) {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
  root.render(
    <React.StrictMode>
      <AppWrappers>
        <BrowserRouter>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </BrowserRouter>
      </AppWrappers>
    </React.StrictMode>
  )
} else {
  hydrateRoot(
    document.getElementById('root') as HTMLElement,
    <React.StrictMode>
      <AppWrappers>
        <BrowserRouter>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </BrowserRouter>
      </AppWrappers>
    </React.StrictMode>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
