import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { ListingArticleInterface } from '../../components/articles-listing/listing-article.component'
import { Category } from '../../types/category.types'

const initialQuery = gql`
  query category($urlKey: String!, $sort: SortInput, $pagination: PaginationInput, $filters: ArticleFiltersInput) {
    category: categoryByUrlKey(urlKey: $urlKey) {
      bannerImage
      title
      image
      smallDescription
      articles(sort: $sort, pagination: $pagination, filters: $filters) {
        total
        limit
        skip
        data {
          id
          urlKey
          title
          image
          date
          smallDescription
          category {
            urlKey
            title
          }
          writer {
            urlKey
            fullname
          }
        }
      }
    }
  }
`

interface CategoryDetailsQueryProps {
  pageSize?: number
  pageNumber?: number
  urlKey: string
}
interface CategoryDetailsQueryInterface {
  bannerImage?: Category['bannerImage']
  pageTitle?: Category['title']
  articles?: ListingArticleInterface[]
  smallDescription?: Category['smallDescription']
  image?: Category['image']
  isLoading: boolean
  hasMore: boolean
  loadMore: () => void
}

const sort = {
  by: 'date',
  direction: 'desc',
}

const useCategoryDetailsQuery = ({
  pageSize = 8,
  pageNumber = 1,
  urlKey,
}: CategoryDetailsQueryProps): CategoryDetailsQueryInterface => {
  const {
    loading: isLoading,
    data: categoryData,
    fetchMore,
  } = useQuery(initialQuery, {
    variables: {
      urlKey,
      sort,
      filters: {
        isPublished: true,
      },
      pagination: {
        limit: pageSize * pageNumber,
      },
    },
    notifyOnNetworkStatusChange: true,
  })

  const data = categoryData?.category
  const hasMore = useMemo(
    () => data?.articles?.total > data?.articles?.data?.length,
    [data?.articles?.data?.length, data?.articles?.total]
  )

  const loadMore = (): void => {
    if (hasMore && !isLoading) {
      const skipSize = data?.articles?.data?.length || 0
      fetchMore({
        variables: {
          urlKey,
          sort,
          filters: {
            isPublished: true,
          },
          pagination: {
            limit: pageSize,
            skip: skipSize,
          },
        },
        updateQuery: (previousData, { fetchMoreResult }) => {
          if (!fetchMoreResult) return previousData
          return {
            category: {
              ...previousData.category,
              articles: {
                ...fetchMoreResult.category.articles,
                data: [
                  ...(previousData?.category?.articles?.data || []),
                  ...(fetchMoreResult.category.articles?.data || []),
                ],
              },
            },
          }
        },
      })
    }
  }

  return {
    bannerImage: data?.bannerImage,
    pageTitle: data?.title,
    smallDescription: data?.smallDescription,
    image: data?.image,
    articles: data?.articles?.data || [],
    isLoading,
    hasMore,
    loadMore,
  }
}

export default useCategoryDetailsQuery
