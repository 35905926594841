import React from 'react'
import { Link } from 'react-router-dom'
import { MEDIA_URL } from '../../consts'
import { Article } from '../../types/article.types'
import RedLabel from '../red-label/red-label.component'

import './featured-blocks.styles.css'

export type FeaturedBlockInterface = Pick<Article, 'id' | 'title' | 'urlKey' | 'category' | 'image'>
interface FeaturedBlocksProps {
  articles?: FeaturedBlockInterface[]
  isLoading: boolean
  customClassName?: string
}

const FeaturedBlocks = (props: FeaturedBlocksProps): JSX.Element => {
  const { articles, customClassName, isLoading } = props
  return (
    <section className={`featured-blocks page-padding ${customClassName || ''}`}>
      <div className='centered-container featured-blocks__container'>
        {articles?.length
          ? articles.map((article) => (
              <article className='featured-blocks__article' key={`featured-block-${article.id}`}>
                <Link title={article.title} to={`/article/${article.urlKey}`} className='featured-blocks__frame'>
                  <img
                    alt={article.title}
                    src={`${MEDIA_URL}resized/710x464/${article.image}`}
                    srcSet={`${MEDIA_URL}resized/355x233/${article.image} 355w, ${MEDIA_URL}resized/710x464/${article.image} 710w`}
                  />
                </Link>
                <div className='featured-blocks__article-content'>
                  <RedLabel
                    label={article.category.title}
                    to={`/category/${article.category?.urlKey}`}
                    customClassName='featured-blocks__article-label'
                  />
                  <Link
                    title={article.title}
                    to={`/article/${article.urlKey}`}
                    className='featured-blocks__article-title'>
                    <h1>{article.title}</h1>
                  </Link>
                </div>
              </article>
            ))
          : null}
      </div>
    </section>
  )
}

export default FeaturedBlocks
