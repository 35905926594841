import React, { useEffect } from 'react'
import { Button, InputField, TextAreaField } from '@qesheq/qesheq-ui'

import { useContactMutation, useInputState } from '../../../hooks'
import './contact-form.styles.css'

const ContactForm = (): JSX.Element => {
  const {
    value: fullname,
    setValue: setFullname,
    error: fullnameError,
    validate: validateFullname,
    onBlur: fullnameOnBlur,
    reset: resetFullname,
  } = useInputState({ initialValue: '', validation: { required: true } })

  const {
    value: email,
    setValue: setEmail,
    error: emailError,
    validate: validateEmail,
    onBlur: emailOnBlur,
    reset: resetEmail,
  } = useInputState({ initialValue: '', validation: { required: true, email: true } })

  const {
    value: phone,
    setValue: setPhone,
    error: phoneError,
    validate: validatePhone,
    onBlur: phoneOnBlur,
    reset: resetPhone,
  } = useInputState<string | undefined>({ initialValue: '', validation: {} })

  const {
    value: message,
    setValue: setMessage,
    error: messageError,
    validate: validateMessage,
    onBlur: messageOnBlur,
    reset: resetMessage,
  } = useInputState<string>({ initialValue: '', validation: { required: true } })

  const { sendContact, isCreated, isLoading, error, reset: resetMutation } = useContactMutation()

  const handleSubmit = async (event: React.SyntheticEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()
    let isFormValid = true
    isFormValid = validateFullname() && isFormValid
    isFormValid = validateEmail() && isFormValid
    isFormValid = validatePhone() && isFormValid
    isFormValid = validateMessage() && isFormValid

    if (!isLoading && isFormValid) {
      const payload = {
        name: fullname,
        email,
        phone,
        message,
      }

      sendContact(payload)
    }
  }

  useEffect(() => {
    if (error) {
      console.log('Error while submittion contact form', error)
      setTimeout(() => {
        resetMutation()
      }, 3000)
    }
  }, [error])

  useEffect(() => {
    if (isCreated) {
      setTimeout(() => {
        resetEmail()
        resetMessage()
        resetFullname()
        resetPhone()
        resetMutation()
        resetMutation()
      }, 5000)
    }
  }, [isCreated])

  return (
    <div className='contact-form page-padding'>
      <div className='contact-form__container centered-container'>
        <form action='' className='contact-form__form' onSubmit={handleSubmit}>
          <InputField
            value={fullname}
            onChange={setFullname}
            errorMessage={fullnameError}
            label='الأسم'
            onBlur={fullnameOnBlur}
            customClassName='contact-form__input-field'
            isDisabled={isLoading}
          />
          <InputField
            value={email}
            onChange={setEmail}
            errorMessage={emailError}
            label='البريد الالكتروني'
            onBlur={emailOnBlur}
            customClassName='contact-form__input-field'
            isDisabled={isLoading}
          />
          <InputField
            value={phone}
            onChange={setPhone}
            errorMessage={phoneError}
            label='رقم الهاتف'
            onBlur={phoneOnBlur}
            customClassName='contact-form__input-field'
            isDisabled={isLoading}
          />
          <TextAreaField
            value={message}
            onChange={setMessage}
            errorMessage={messageError}
            label='الرسالة'
            onBlur={messageOnBlur}
            customClassName='contact-form__text-area-field'
            isDisabled={isLoading}
          />
          <Button
            label='أرسل'
            type='input'
            layout='solid'
            customClassName='contact-form__submit-button'
            isDisabled={isLoading}
          />

          <div className={`contact-form__error-container ${error ? 'contact-form__error-container--visible' : ''}`}>
            <span className='contact-form__error-title'>عذراً</span>
            <span className='contact-form__error-message'> حدث خطأ ما</span>
          </div>

          <div
            className={`contact-form__success-container ${
              isCreated ? 'contact-form__success-container--visible' : ''
            }`}>
            <span className='contact-form__success-title'>شكرا لك</span>
            <span className='contact-form__success-message'>لقد تم إستلام رسالتك</span>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ContactForm
