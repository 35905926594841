import React, { useContext } from 'react'
import { Button, FacebookIcon, InstagramIcon, TwitterLogoIcon } from '@qesheq/qesheq-ui'
import { Link, Outlet } from 'react-router-dom'

import 'react-loading-skeleton/dist/skeleton.css'
import './footer.styles.css'
import { LayoutDataContext } from '../../context/layout-data.context'

const FooterLayout = (): JSX.Element => {
  const { categories, isLoading } = useContext(LayoutDataContext)

  return (
    <div className='footer-layout'>
      <Outlet />

      <footer className='footer-layout__footer page-padding'>
        <div className='footer-layout__container centered-container'>
          <nav className='footer-layout__sitemap'>
            <span className='footer-layout__sitemap-seperator' />
            <span className='footer-layout__sitemap-seperator' />
            <span className='footer-layout__sitemap-seperator' />
            <span className='footer-layout__sitemap-seperator' />

            {categories?.map((category) => (
              <Link
                title={category.title}
                className='footer-layout__sitemap-link'
                to={`/category/${category.urlKey}`}
                key={`footer-sitemap-link-${category.id}`}>
                <h2>{category.title}</h2>
              </Link>
            ))}
            <Link title='المواضيع' className='footer-layout__sitemap-link' to='/categories'>
              <h2>المواضيع</h2>
            </Link>
            <Link title='عن كشك' className='footer-layout__sitemap-link' to='/about'>
              <h2>عن كشك</h2>
            </Link>
            <Link title='لاعلاناتكم' className='footer-layout__sitemap-link' to='/advertise'>
              <h2>لاعلاناتكم</h2>
            </Link>
            <Link title='الكتّاب' className='footer-layout__sitemap-link' to='/writers'>
              <h2>الكتّاب</h2>
            </Link>
            <Link title='إتصل بنا' className='footer-layout__sitemap-link' to='/contact'>
              <h2>إتصل بنا</h2>
            </Link>
          </nav>
          <div className='footer-layout__actions'>
            <div className='footer-layout__become-a-writer'>
              <span className='footer-layout__become-a-writer-title'>هل أنت كاتب؟ إنضم إلى كشك</span>

              <Button
                label='تسجل الآن'
                href='/become-a-writer'
                type='link'
                layout='solid'
                customClassName='footer-layout__become-a-writer-button'
              />
            </div>
            <div className='footer-layout__social'>
              <span className='footer-layout__social-title'>تواصل معنا</span>
              <ul className='footer-layout__social-list'>
                <li>
                  <Button
                    label='Instagram'
                    type='link'
                    href='https://facebook.com'
                    layout='icon-solid'
                    icon={<InstagramIcon />}
                    customClassName='footer-layout__social-icon'
                    external
                  />
                </li>
                <li>
                  <Button
                    label='Twitter'
                    type='link'
                    href='https://tiwtter.com'
                    layout='icon-solid'
                    icon={<TwitterLogoIcon />}
                    customClassName='footer-layout__social-icon'
                    external
                  />
                </li>
                <li>
                  <Button
                    label='Facebook'
                    type='link'
                    href='https://facebook.com'
                    layout='icon-solid'
                    icon={<FacebookIcon />}
                    customClassName='footer-layout__social-icon'
                    external
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default FooterLayout
