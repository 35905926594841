import { gql, useQuery } from '@apollo/client'

const query = gql`
  query contact {
    contact {
      email: smallDescription
      image
      metaTitle
      metaDescription
      metaImage
    }
  }
`

interface ContactQueryInterface {
  image?: string
  email?: string
  metaTitle?: string
  metaDescription?: string
  metaImage?: string
  isLoading: boolean
}

const useContactQuery = (): ContactQueryInterface => {
  const { loading: isLoading, data: contactData } = useQuery(query)

  return {
    email: contactData?.contact?.email,
    image: contactData?.contact?.image,
    metaTitle: contactData?.contact?.metaTitle,
    metaDescription: contactData?.contact?.metaDescription,
    metaImage: contactData?.contact?.metaImage,
    isLoading,
  }
}

export default useContactQuery
