import { FocusEvent, useCallback, useEffect, useMemo, useState } from 'react'

import { isEmail, isUrl } from '../utils'

export type InputState = 'pristine' | 'dirty'

interface ValidationRules {
  required?: boolean
  min?: number
  max?: number
  email?: boolean
  url?: boolean
}

interface InputStateHookInterface<T> {
  value: T
  setValue: (value: T) => void
  state: InputState
  error: string | null
  onBlur: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  validate: () => boolean
  reset: () => void
}

interface InputStateHookProps<T> {
  initialValue: T
  validation?: ValidationRules
}

const validate = <T>(value: T, validation?: ValidationRules): string | null => {
  if (!validation) {
    return null
  }
  if (validation.required && (Array.isArray(value) ? !value.length : !value)) {
    return 'حقل الزامي'
  }

  if (validation.min) {
    if (typeof value === 'string' && validation.min > value.length) {
      return `${validation.min} الحد الأدنى لعدد الحروف هو`
    }
    if (typeof value === 'number' && validation.min > value) {
      return `${validation.min} رجاء إدخال عدد أقل من أو يساوي`
    }
  }

  if (validation.max) {
    if (typeof value === 'string' && validation.max < value.length) {
      return `${validation.max} الحد الأقصى لعدد الحروف هو`
    }
    if (typeof value === 'number' && validation.max < value) {
      return `${validation.max} رجاء إدخال عدد أكبر من أو يساوي`
    }
  }

  if (validation.url && value && (typeof value !== 'string' || !isUrl(value))) {
    return 'رابط غير صالح'
  }
  if (validation.email && value && (typeof value !== 'string' || !isEmail(value))) {
    return 'رجاء إدخال عنوان بريد إلكتروني صحيح'
  }

  return null
}

const evaluateState = <T>(previousState: InputState, value: T): InputState =>
  previousState === 'dirty' || !!value ? 'dirty' : 'pristine'

const useInputState = <T>({ initialValue, validation }: InputStateHookProps<T>): InputStateHookInterface<T> => {
  const [value, setValue] = useState<T>(initialValue)
  const [state, setState] = useState<InputState>('pristine')
  const [validationMessage, setValidationMessage] = useState<InputStateHookInterface<T>['error']>(null)

  const validateAndSetMessage = (): void => {
    const message = validate<T>(value, validation)
    setValidationMessage(message)
  }
  /**
   * Description: Validates input and sets its state as dirty
   *
   * @returns boolean, weather the input is valid or not
   */
  const validateAndForceDirtyState = (): boolean => {
    validateAndSetMessage()
    setState('dirty')
    return !validationMessage
  }

  useEffect(() => {
    setState((previousState) => evaluateState(previousState, value))
  }, [value])

  useEffect(() => {
    validateAndSetMessage()
  }, [validation, value])

  const handleOnBlur = (): void => {
    validateAndSetMessage()
  }

  const reset = useCallback(() => {
    setValue(initialValue)
    setState('pristine')
    setValidationMessage(null)
  }, [])

  const error: InputStateHookInterface<T>['error'] = useMemo(
    () => (state === 'dirty' ? validationMessage : null),
    [state, validationMessage]
  )
  return { value, setValue, state, error, onBlur: handleOnBlur, validate: validateAndForceDirtyState, reset }
}

export default useInputState
