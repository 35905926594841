import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { useCallback, useEffect, useState } from 'react'
import { ListingWriter } from '../../components/writers-listing/writers-listing.component'
import { Page } from '../../types/page.types'

const writerFields = `
  total
  limit
  skip
  data {
    id
    urlKey
    fullname
    image
    articlesCount
  }
`

const initialQuery = gql`
  query writers(
    $sort: SortInput
    $pagination: PaginationInput
    $filters: BaseFiltersInput
  ) {
    page: writersPage {
      image
      metaTitle
      metaDescription
      metaImage
    }
    writers(sort: $sort, pagination: $pagination, filters: $filters) {
      ${writerFields}
    }
  }
`
const writersQuery = gql`
  query writers(
    $sort: SortInput
    $pagination: PaginationInput
    $filters: BaseFiltersInput
  ) {
    writers(sort: $sort, pagination: $pagination, filters: $filters) {
      ${writerFields}
    }
  }
`
interface WritersQueryProps {
  pageSize?: number
  pageNumber?: number
}
interface WritersQueryInterface {
  bannerImage?: string
  writers?: ListingWriter[]
  metaTitle?: Page['metaTitle']
  metaDescription?: Page['metaDescription']
  metaImage?: Page['metaImage']
  isLoading: boolean
  isLoadingMore: boolean
  hasMore: boolean
  loadMore: () => void
}

const writersSort = {
  by: 'articlesCount',
  direction: 'desc',
}

const writersFilters = {
  isPublished: true,
}

const useWritersQuery = ({ pageSize = 8, pageNumber = 1 }: WritersQueryProps): WritersQueryInterface => {
  const [page, setPage] = useState(pageNumber)
  const [writers, setWriters] = useState<ListingWriter[]>([])
  const [hasMore, setHasMore] = useState(false)
  const [total, setTotal] = useState(0)

  const { loading: isLoading, data: pageData } = useQuery(initialQuery, {
    variables: {
      sort: writersSort,
      filters: writersFilters,
      pagination: {
        limit: pageSize * page,
      },
    },
    notifyOnNetworkStatusChange: true,
  })

  const [fetchMoreWriters, { loading: isLoadingMore, data: writersData }] = useLazyQuery(writersQuery)

  useEffect(() => {
    if (page > pageNumber) {
      fetchMoreWriters({
        variables: {
          sort: writersSort,
          filters: writersFilters,
          pagination: {
            limit: pageSize,
            skip: pageSize * (page - 1),
          },
        },
        notifyOnNetworkStatusChange: true,
      })
    }
  }, [page, pageNumber])

  useEffect(() => {
    if (pageData?.writers?.data) {
      setWriters(pageData?.writers?.data)
      setTotal(pageData?.writers?.total)
    }
  }, [pageData?.writers?.data])

  useEffect(() => {
    if (writersData?.writers?.data) {
      setWriters((previousWriters) => [...previousWriters, ...(writersData?.writers?.data || [])])
      setTotal(writersData?.writers?.total)
    }
  }, [writersData?.writers])

  useEffect(() => {
    setHasMore(total > writers.length)
  }, [total, writers])

  const loadMore = useCallback(() => {
    setPage((previousValue) => previousValue + 1)
  }, [])

  const returnedWriters = writers?.length ? writers : pageData?.writers?.data

  return {
    bannerImage: pageData?.page?.image,
    writers: returnedWriters || [],
    metaTitle: pageData?.page?.metaTitle,
    metaDescription: pageData?.page?.metaDescription,
    metaImage: pageData?.page?.metaImage,
    isLoading,
    isLoadingMore,
    hasMore,
    loadMore,
  }
}

export default useWritersQuery
