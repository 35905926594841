import { Button, Descendant, FacebookIcon, GlobeIcon, QEditor, TwitterLogoIcon } from '@qesheq/qesheq-ui'
import React, { useMemo } from 'react'
import { MEDIA_URL } from '../../consts'

import '@qesheq/qesheq-ui/dist/css/q-editor.styles.css'

import './writer-hero.styles.css'

const DEFAULT_BANNER_IMAGE = '/images/writer-default-banner.jpg'

interface WriterHeroProps {
  coverImage?: string
  image?: string
  bio?: Descendant[]
  fullname?: string
  website?: string
  facebook?: string
  twitter?: string
  isLoading: boolean
}

const WriterHero = (props: WriterHeroProps): JSX.Element => {
  const { coverImage, isLoading, bio, facebook, fullname, image, twitter, website } = props

  const heroImageSrc = useMemo(() => {
    if (!fullname) return undefined
    return coverImage ? `${MEDIA_URL}resized/1600x280/${coverImage}` : DEFAULT_BANNER_IMAGE
  }, [coverImage, isLoading])

  const heroImageSrcSet = useMemo(
    () =>
      fullname && !coverImage
        ? undefined
        : `${MEDIA_URL}resized/1600x280/${coverImage} 1600w, ${MEDIA_URL}resized/767x280/${coverImage} 767w`,
    [coverImage, isLoading]
  )

  return (
    <section className='writer-hero '>
      <div
        className={`writer-hero__banner page-padding ${fullname && !bio ? 'writer-hero__banner--bottom-margin' : ''}`}>
        {heroImageSrc && (
          <img src={heroImageSrc} srcSet={heroImageSrcSet} alt={fullname} className='writer-hero__banner-image' />
        )}
        <div className='writer-hero__content centered-container'>
          {image && (
            <div className='writer-hero__profile-frame'>
              <img src={`${MEDIA_URL}resized/238x238/${image}`} alt={fullname} className='writer-hero__profile-image' />
            </div>
          )}
          <h1 className='writer-hero__fullname'>{fullname}</h1>
          <nav className='writer-hero__contacts'>
            {website && (
              <a
                title={website}
                href={website}
                target='_blank'
                rel='noreferrer'
                className='writer-hero__contact-link writer-hero__website'>
                <span className='writer-hero__website-text'>{website}</span>
                <GlobeIcon className='writer-hero__website-icon' />
              </a>
            )}
            {twitter && (
              <Button
                label='Twitter'
                type='link'
                href={twitter}
                layout='icon-solid'
                icon={<TwitterLogoIcon />}
                customClassName='writer-hero__contact-link writer-hero__twitter'
                target='_blank'
                external
              />
            )}
            {facebook && (
              <Button
                label='Facebook'
                type='link'
                href={facebook}
                layout='icon-solid'
                icon={<FacebookIcon />}
                customClassName='writer-hero__contact-link writer-hero__facebook'
                target='_blank'
                external
              />
            )}
          </nav>
        </div>
      </div>
      <div className='writer-hero__description page-padding'>
        <div className='writer-hero__description-container centered-container'>
          <QEditor layout='read-only' value={bio} readOnly customClassName='writer-hero__bio-editor' />
        </div>
      </div>
    </section>
  )
}

export default WriterHero
