import React, { useContext, useEffect, useState } from 'react'
import { Button, CloseIcon, ListIcon, MagnifyingGlassIcon, PlusIcon } from '@qesheq/qesheq-ui'
import { Link, Outlet, useLocation } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

import './header.styles.css'
import 'react-loading-skeleton/dist/skeleton.css'
import { LayoutDataContext } from '../../context/layout-data.context'

const skeletonLoopArray = [...Array(5)]

const HeaderLayout = (): JSX.Element => {
  const { pathname } = useLocation()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const { categories, isLoading } = useContext(LayoutDataContext)

  const closeMenu = (): void => {
    setIsMenuOpen(false)
  }
  const openMenu = (): void => {
    setIsMenuOpen(true)
  }

  useEffect(() => {
    setIsMenuOpen(false)
  }, [pathname])

  return (
    <div className='header-layout'>
      <span
        role='button'
        className={`header-layout__overlay ${isMenuOpen ? 'header-layout__overlay--visible' : ''}`}
        onClick={closeMenu}
        onKeyPress={closeMenu}
        tabIndex={0}
      />
      <header className='header-layout__header page-padding'>
        <div className='header-layout__container centered-container'>
          <div className='header-layout__right-content'>
            <Button
              type='button'
              label='القائمة'
              layout='icon'
              icon={<ListIcon />}
              customClassName='header-layout__burger-menu'
              onClick={openMenu}
            />
            <Link to='/' className='header-layout__logo-container'>
              <img className='header-layout__logo' src='/images/white-logo-92x41.svg' alt='كشك' />
            </Link>
            <nav
              className={`header-layout__navigation-container ${
                isMenuOpen ? 'header-layout__navigation-container--open' : ''
              }`}>
              <Button
                type='button'
                layout='icon'
                icon={<CloseIcon customClassName='header-layout__close-icon' />}
                customClassName='header-layout__close-button'
                label='أغلق'
                onClick={closeMenu}
              />

              <div className='header-layout__scoll-wrapper'>
                <ul className='header-layout__navigation-list'>
                  {isLoading && !categories?.length
                    ? skeletonLoopArray.map((_, index) => (
                        <li
                          className='header-layout__nav-item header-layout__nav-item--in-header'
                          // eslint-disable-next-line react/no-array-index-key
                          key={`header-skeleton-${index}`}>
                          <span className='header-layout__nav-link'>
                            <h2>
                              <Skeleton containerClassName='header-layout__nav-item-skeleton' />
                            </h2>
                          </span>
                        </li>
                      ))
                    : categories?.map((category) => (
                        <li
                          className={`header-layout__nav-item ${
                            category.inHeader ? 'header-layout__nav-item--in-header' : ''
                          }`}
                          key={`header-category-${category.urlKey}`}>
                          <Link className='header-layout__nav-link' to={`/category/${category.urlKey}`}>
                            <h2>{category.title}</h2>
                          </Link>
                        </li>
                      ))}
                </ul>
                <Link to='/categories' className='header-layout__navigation-more'>
                  المزيد
                  <PlusIcon customClassName='header-layout__navigation-more-icon' />
                </Link>
                <ul className='header-layout__extended-navigation'>
                  <li className='header-layout__nav-item'>
                    <Link className='header-layout__nav-link' to='/writers'>
                      الكتّاب
                    </Link>
                  </li>
                  <li className='header-layout__nav-item'>
                    <Link className='header-layout__nav-link' to='/about'>
                      عن كشك
                    </Link>
                  </li>
                  <li className='header-layout__nav-item'>
                    <Link className='header-layout__nav-link' to='/become-a-writer'>
                      إنضم إلى كشك
                    </Link>
                  </li>
                  <li className='header-layout__nav-item'>
                    <Link className='header-layout__nav-link' to='/advertise'>
                      لاعلاناتكم
                    </Link>
                  </li>
                  <li className='header-layout__nav-item'>
                    <Link className='header-layout__nav-link' to='/contact'>
                      إتصل بنا
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <div className='header-layout__left-content'>
            <Button
              type='button'
              icon={<MagnifyingGlassIcon />}
              layout='icon'
              label='بحث'
              customClassName='header-layout__search-icon'
            />
          </div>
        </div>
      </header>
      <Outlet />
    </div>
  )
}

export default HeaderLayout
