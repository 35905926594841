import React, { useCallback, useRef } from 'react'
import { Link } from 'react-router-dom'
import { MEDIA_URL } from '../../consts'
import { Writer } from '../../types/writer.types'

import './writers-listing.styles.css'

export type ListingWriter = Pick<Writer, 'fullname' | 'id' | 'urlKey' | 'image' | 'articlesCount'>

interface WritersListingProps {
  writers?: ListingWriter[]
  listKey: string
  customContainerClassName?: string
  customWriterClassName?: string
  loadMore: () => void
  hasMore: boolean
  isLoading: boolean
}

const WritersListing = (props: WritersListingProps): JSX.Element => {
  const {
    writers,
    listKey,
    customContainerClassName = '',
    customWriterClassName = '',
    hasMore,
    isLoading,
    loadMore,
  } = props

  const observer = useRef<IntersectionObserver>()
  const lastWriterReference = useCallback(
    (element: HTMLElement | null) => {
      if (!loadMore || isLoading) {
        return
      }
      if (observer.current) {
        observer.current.disconnect()
      }
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && !isLoading) {
          loadMore()
        }
      })
      if (element) observer?.current?.observe(element)
    },
    [isLoading, loadMore, hasMore]
  )
  return (
    <section className={`writers-listing ${customContainerClassName}`}>
      <div className='writers-listing__container centered-container'>
        {writers?.map(({ fullname, image, id, urlKey, articlesCount }, index) => (
          <article
            className={`writers-listing__writer ${customWriterClassName}`}
            key={`writers-${listKey}-${id}`}
            ref={index + 1 === writers.length ? lastWriterReference : undefined}>
            <Link className='writers-listing__writer-frame' to={`/writer/${urlKey}`} title={fullname}>
              <img
                src={`${MEDIA_URL}resized/238x238/${image}`}
                srcSet={`${MEDIA_URL}resized/238x238/${image}, ${MEDIA_URL}resized/476x476/${image} 2x,${MEDIA_URL}resized/476x476/${image} 3x`}
                alt={fullname}
                className='writers-listing__writer-image'
              />
            </Link>
            <Link to={`/writer/${urlKey}`} title={fullname}>
              <h1 className='writers-listing__writer-fullname'>{fullname}</h1>
            </Link>
            <span className='writers-listing__writer-articles-count'>{articlesCount} مقالات</span>
          </article>
        ))}
      </div>
    </section>
  )
}

export default WritersListing
