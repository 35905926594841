import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { ListingCategoryType } from '../../components/categories-listing/listing-category.component'
import { Page } from '../../types/page.types'

const categoriesQuery = gql`
  query categories($sort: SortInput, $pagination: PaginationInput, $filters: CategoryFiltersInput) {
    page: pageByUrlKey(urlKey: "categories") {
      metaTitle
      metaDescription
      metaImage
    }
    categories(sort: $sort, pagination: $pagination, filters: $filters) {
      total
      limit
      skip
      data {
        id
        urlKey
        title
        image
      }
    }
  }
`
interface CategoriesQueryProps {
  pageSize?: number
  pageNumber?: number
}
interface CategoriesQueryInterface {
  categories?: ListingCategoryType[]
  metaTitle?: Page['metaTitle']
  metaDescription?: Page['metaDescription']
  metaImage?: Page['metaImage']
  isLoading: boolean
  hasMore: boolean
  loadMore: () => void
}

const useCategoriesQuery = ({ pageSize = 8, pageNumber = 1 }: CategoriesQueryProps): CategoriesQueryInterface => {
  const {
    loading: isLoading,
    data: categoriesData,
    fetchMore,
  } = useQuery(categoriesQuery, {
    variables: {
      sort: {
        by: 'dateCreated',
        direction: 'desc',
      },
      filters: {
        isPublished: true,
      },
      pagination: {
        limit: pageSize * pageNumber,
      },
    },
    notifyOnNetworkStatusChange: true,
  })

  const categories = categoriesData?.categories?.data || []
  const total = categoriesData?.categories?.total || 0
  const hasMore = useMemo(() => total > categories.length, [categories, total])

  const loadMore = (): void => {
    if (hasMore && !isLoading) {
      fetchMore({
        variables: {
          sort: {
            by: 'dateCreated',
            direction: 'desc',
          },
          filters: {
            isPublished: true,
          },
          pagination: {
            limit: pageSize,
            skip: categories?.length || 0,
          },
        },
        updateQuery: (previousData, { fetchMoreResult }) => {
          if (!fetchMoreResult) return previousData
          return {
            categories: {
              ...previousData.categories,
              data: [...(previousData?.categories?.data || []), ...(fetchMoreResult.categories?.data || [])],
            },
          }
        },
      })
    }
  }

  return {
    categories: categories || [],
    metaTitle: categoriesData?.page?.metaTitle,
    metaDescription: categoriesData?.page?.metaDescription,
    metaImage: categoriesData?.page?.metaImage,
    isLoading,
    hasMore,
    loadMore,
  }
}

export default useCategoriesQuery
