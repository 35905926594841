import { BASE_URL, months } from './consts'

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString)
  return `${date.getUTCDate()} ${months[date.getUTCMonth()]} ${date.getUTCFullYear()}`
}

export const emailShareLink = (urlKey?: string): string => `mailto:?body=${encodeURI(`${BASE_URL}article/${urlKey}`)}`

export const facebookShareLink = (urlKey?: string): string =>
  `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(`${BASE_URL}article/${urlKey}`)}`

export const twitterShareLink = (urlKey?: string): string =>
  `https://twitter.com/home?status=${encodeURI(`${BASE_URL}article/${urlKey}`)}`

export const whatsappShareLink = (urlKey?: string): string =>
  `https://api.whatsapp.com/send?text=${encodeURI(`${BASE_URL}article/${urlKey}`)}`

export const linkedinShareLink = (urlKey?: string): string =>
  `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(`${BASE_URL}article/${urlKey}`)}`

export const isEmail = (email: string): boolean => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)

export const isUrl = (email: string): boolean =>
  /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/.test(email)
